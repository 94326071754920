html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('backCab.png');
    background-position: right;
    background-repeat: no-repeat; */
  color: #000;
  font-variant-numeric: lining-nums;
}

body::-webkit-scrollbar {
  width: 12px; /* ширина для вертикального скролла */
  height: 8px; /* высота для горизонтального скролла */
}

/* ползунок скроллбара */
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #cddbec;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card-title {
  font-family: 'Raleway';
  font-size: 20px;
  line-height: 23px;
}

.card-text {
  font-family: 'Raleway-medium';
  font-size: 16px;
  line-height: 19px;
}

li {
  list-style-type: none;
}

._container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
}

body.hidden {
  overflow: hidden;
}

.win-cookie {
  position: fixed;
  width: 1280px;
  bottom: 0;
  left: calc((100% - 1280px) / 2);
  z-index: 10;
  background: #fff;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
}
#win-cookie-btn {
  text-align: center;
  background: #e74137;
  border-radius: 20px;
  line-height: 100%;
  max-width: 240px;
  transition: all .3s ease;
  width: 100%;
  margin-bottom: 0;
  color: #fff;
  padding: 0 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
#win-cookie-btn:hover {
  filter: drop-shadow(0 15px 29px rgba(231, 65, 55, .51));
}
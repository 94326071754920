.body {
  background: #eeedea;
  font-family: 'Roboto';
}

.container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px;

  & p {
    margin: 0;
  }
}

.body {
  background: #eeedea;
  font-family: 'Roboto';
}
.amount-row {
  display: flex;
}

.amount-row span {
  margin: 0 5px;
}

.amount-row button {
  box-shadow: none;;
}
.container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 20px 15px;

  & p {
    margin: 0;
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 28px !important;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 767px) {
    // display: none;
    thead {
      display: none;
    }

    tr {
      flex-wrap: wrap;
    }
  }

  & tr:first-child th {
    padding: 0 14px;
  }

  & tr:not(:first-child) > td {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & tr {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-bottom: 29px;
    @media (max-width: 767px) {
      border-bottom: 1px solid #888;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    & > * {
      padding: 0 8px;

      @media (max-width: 767px) {
        padding: 0;
      }
    }

    & > *:first-child {
      flex: 0 0 35%;
      @media (max-width: 767px) {
        flex: 0 0 49%;
        margin-right: 10px;
      }
    }

    & > *:not(:first-child) {
      flex: 0 0 calc((100% - 35%) / 3);
      @media (max-width: 767px) {
        flex: 0 0 calc(49% - 20px);
      }
    }

    & > *:last-child {
      position: absolute;
    }
  }

  & tr:not(:first-child) {
    & > *:not(:nth-last-child(-n + 2)) {
      border-right: 1px solid rgba(217, 217, 217, 0.54);
    }
  }

  & th {
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: #423f3f;
  }

  & td:last-child {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 7px;
    background: #e3a8a8;
    border-radius: 5px;
    cursor: pointer;
  }
}

.img {
    width: auto;
    height: 47px;
    margin-right: 10px;
    border: 1px solid #bebebe;
    border-radius: 8px;
    position: relative;
    box-sizing: content-box;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 37px;
  margin-bottom: 80px;

  & > *:first-child {
    flex: 0 0 calc(70% - 24px);
  }

  & > *:last-child {
    flex: 0 0 30%;
  }

  @media (max-width: 1050px) {
    flex-direction: column;

    & > *:first-child {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 30px;
    }

    & > *:last-child {
      flex: 0 0 50%;
      margin: 0 auto;
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    & > *:last-child {
      flex: 0 0 100%;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.flexItem {
  background: #fff;
  border-radius: 30px;
  padding: 24px 32px;
}

.tableImg {
  width: 10px;
}

.tableHeader {
  display: flex;
  align-items: center;

  & > *:last-child {
    margin-left: 5px;
  }
}

.flexCard {
  display: flex;
  align-items: center;
}

.cardTitle {
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  font-weight: 700;
  margin-left: 9px !important;
  font-family: 'Roboto';

  @media (max-width: 767px) {
    font-size: 24px;
    margin-left: 0 !important;
    line-height: 1.5;
  }
}

.paragraph {
  margin-top: 13px !important;
  font-size: 13px;
  line-height: 15px;

  color: #423f3f;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 1.5;
  }
}

.price {
  font-size: 17px;
  line-height: 20px;
  color: #000000;

  @media (max-width: 767px) {
    margin-right: 14px !important;
  }
}

.oldPrice {
  font-size: 17px;
  line-height: 20px;
  color: #a9a9a9;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-right: 5px !important;
  }
}

.discount {
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  color: #ffffff;
  background: #eb5757;
  border-radius: 30px;
  padding: 4px 7px;
}

.flexDiscount {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.itogo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span.title {
    margin-bottom: 16px !important;
  }
}

.link {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: #eaa023;
  padding: 13px 0;
  transition: all 0.3s ease;
  border-radius: 30px;
  border: 1px solid #eaa023;

  &:hover {
    background: #eaa023;
    color: #ffffff;
  }

  &.first {
    margin-bottom: 18px;
    margin-top: 18px;
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    flex: 0 0 70%;
  }
}

.flexMobile {
  display: flex;
  align-items: center;
  width: 60%;

  & > div {
    margin-left: 20px;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 20px;
  font-size: 20px;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.mobileButton {
  width: 60%;
  border-radius: 30px;
  background: #eb5757;
  color: #fff;
  border: none;
  padding: 14px 0;
  margin-top: 20px;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.mobile {
  @media (min-width: 768px) {
    display: none;
  }
}




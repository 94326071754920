.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 60px;
}

.text {
  margin-top: 60px;
  line-height: 150%;

  font-family: 'Raleway';
  font-feature-settings: 'pnum' on, 'lnum' on;

  & a {
    color: #d83a2e;
    font-weight: 700;
  }

  & h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
  }
}

.certificates-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.certificates-page-row-col {
  margin-top: 3%;
}

@media (max-width: 767px) {
  .certificates-page-image-list {
    display: block;
  }
}

.flexFilters {
  position: relative;
  padding: 5px 0 5px 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #fff;
  border-radius: 30px;
  margin-top: 5px;

  @media (max-width: 1090px) {
    display: inline-block;
    padding-right: 16px;
  }

  &.withoutPadding {
    padding-left: 10px;
  }

  &:hover {
    background: #eaa023;
    border-radius: 30px;
    color: #fff;

    & .filter {
      color: #fff;
    }
  }

  &.active {
    background: #eaa023;
    border-radius: 30px;
    color: #fff;

    & .filter {
      color: #fff;
    }
  }

  & > img {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.3s ease;
  }

  & > img:first-child {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
  }

  & > img:last-child {
    visibility: hidden;
    transition: all 0.3s ease;
  }

  &:hover,
  &.active {
    & > img:first-child {
      opacity: 0;
      visibility: hidden;
    }

    & > img {
      opacity: 1;
    }

    & > img:last-child {
      opacity: 1;
      visibility: visible;
    }
  }
}

.filter {
  font-size: 16px;
  line-height: 19px;
  padding: 5px;
  color: #8a8888;
}

.body {
  background: #eeedea;
  min-height: 100vh;
}

.container {
  max-width: 1118px;
  margin: 0 auto;
  padding: 38px 15px 60px 15px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;

  & > *:first-child {
    flex: 0 0 25%;
  }

  & > *:last-child {
    flex: 0 0 calc(75% - 38px);
    width: calc(75% - 38px);
  }

  @media (max-width: 991px) {
    flex-direction: column;

    & > *:first-child {
      flex: 0 0 100%;
      width: 100%;
    }

    & > *:last-child {
      flex: 0 0 100%;
      width: 100%;
      margin-top: 20px;
    }
  }
}

.margin {
  margin-left: 14px;
}

.flexes {
  display: flex;
}

.blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: -1;

  &.active {
    z-index: 9999;
    background: rgba(255, 255, 255, 0.5);
  }
}

.tableWrapper {
  margin: 30px 0 20px;
}

.table {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  width: 100%;

  & > tr > *:not(:last-child) {
    border-right: 1px solid #bebebe;
  }

  & > tr:not(:last-child) {
    border-bottom: 1px solid #bebebe;
  }

  & > tr > * {
    text-align: center;
  }
}

.btn {
  margin-top: 20px;
  display: inline-block;
}

.flex {
  display: flex;
  justify-content: space-between;

  & > *:first-child {
    width: 275px;
    height: 262px;
    background: rgba(217, 217, 217, 0.54);
    border-radius: 15px;
    overflow: hidden;
    flex: 0 0 275px;

    &:hover .img.active {
      transform: scale(1.05) translate(-50%, 0);
    }
  }

  & > *:last-child {
    flex: 0 0 calc(100% - 275px - 33px);
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;

    & > *:first-child {
      margin-bottom: 20px;
    }
  }
}

.flexItem {
  position: relative;
}

.img.active {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease;
  max-height: 100%;
  object-fit: cover;
}

.staticImages {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 15px;
  bottom: 0;

  & > * {
    margin-bottom: 10px;
  }
}

.imgDefault {
  width: 19px;
  height: 45px;
  padding: 15px 20px 0;
  box-sizing: content-box;
  background: #ffffff;
  border-radius: 15px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  cursor: pointer;

  &.active {
    border: 1px solid #eaa023;
  }
}

.title {
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  font-weight: 700;
  color: #000000;
}

.paragraph {
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px !important;
  color: #5e5a5a;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }

  & > *:last-child {
    margin: 0 auto;

    @media (max-width: 1100px) {
      margin: 0 0 24px 0;
    }
  }
}

.flexItem {
  flex: 0 0 33.333%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1100px) {
    flex: 0 0 49%;
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  @media (max-width: 650px) {
    flex: 0 0 70%;
    width: 70%;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 70px auto;
}

.flexImage {
  height: 84px;
  width: 80px;

  & > img {
    width: 100%;
    height: 100%;
  }

  & > .monitor {
    height: 100%;
    width: 120%;
  }
}

.title {
  font-size: 16px;
  font-family: 'Raleway';
  line-height: 19px;
  padding: 0 10px;
  margin-top: 15px;
  text-align: center;
  flex: 1 1 auto;
}

.paragraph {
  text-align: center;
  background: #e74137;
  border-radius: 30px;
  line-height: 120%;
  max-width: 240px;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 0;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }

  & > a,
  span {
    color: #fff;
    line-height: 120%;
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }
}

.flexContent {
  margin-left: 10px;
  flex: 0 0 50%;
}

.form {
  width: 406px;
  padding: 37px 33px;
  box-sizing: border-box;
  margin: 45px auto 45px;
  background: #ffffff;
  border-radius: 15px;
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 21px;
}

.defaultInput {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #423f3f;
  padding: 19px 12px;
  outline: none;
  margin-bottom: 18px;
}

.button {
  width: 100%;
  background: #eaa023;
  border-radius: 30px;
  padding: 12px 0;
  border: none;
  transition: all 0.3s ease;
  margin-top: 10px;
  display: block;
  text-align: center;

  color: #fff;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));

    color: #fff;
  }

  &.extra {
    margin-bottom: 37px;
  }
}

.paragraph {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 19px;
  text-align: center;
  width: 90%;
  margin: 19px auto 0;
  color: #bebebe;
  margin-bottom: 17px;

  @media (max-width: 767px) {
    width: 100%;
  }
}

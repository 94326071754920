.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 60px;
}

.tableWrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
  margin-top: 60px;
  min-width: 500px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  & > * {
    display: flex;
    align-items: center;
    padding: 15px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  & > * > *:not(:first-child):not(:last-child) {
    padding: 0 10px;
  }

  & > *:not(:first-child) {
    min-height: 70px;
  }

  & > * > * {
    flex: 0 0 30%;
    font-family: 'Raleway';
    word-break: break-all;
    text-align: center;
  }

  & > *:first-child {
    border-top: 3px solid #d83a2e;
    border-bottom: 3px solid #d83a2e;
  }

  & > * > *:first-child {
    padding-right: 10px;
    flex: 0 0 10%;
  }

  & > * > *:last-child {
    padding-left: 10px;
  }
}

.paragraph {
  font-family: 'Raleway';
  font-size: 20px;
  font-weight: bold;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-top: 30px;
}

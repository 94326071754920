.flex {
  display: flex;
  justify-content: space-between;

  & > *:first-child {
    flex: 0 0 35%;

    @media (max-width: 1100px) {
      margin-top: 0 !important;
    }
  }

  & > *:last-child {
    flex: 0 0 60%;
  }

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
  }
}

.paragraph {
  margin: 0 auto;
  display: block;
  background: #e74137;
  border-radius: 30px;
  line-height: 120%;
  max-width: 240px;
  transition: all 0.3s ease;
  width: 100%;
  margin-bottom: 0;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }

  & > a {
    color: #fff;
    line-height: 120%;
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.body {
  background: #eeedea;
  font-family: 'Roboto';
}

.container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px;

  & p {
    margin: 0;
  }
}

.flex {
  display: flex;
  padding: 20px 0;
  align-items: flex-start;

  & > *:first-child {
    flex: 0 0 25%;
  }

  & > *:last-child {
    flex: 0 0 75%;
    padding-left: 45px;
    box-sizing: border-box;
  }

  @media (max-width: 1090px) {
    flex-direction: column;
    & > *:first-child {
      flex: 0 0 100%;
      width: 100%;
    }

    & > *:last-child {
      flex: 0 0 100%;
      padding-left: 0;
    }
  }
}

.filtersWrapper {
  background: #fff;
  border-radius: 15px;
  margin-bottom: 35px;

  @media (max-width: 1090px) {
    margin-bottom: 30px;

    & > * {
      display: flex;

      & > * {
        flex: 0 0 50%;
      }

      @media (max-width: 767px) {
        flex-direction: column;

        & > * {
          flex: 0 0 100%;
        }
      }
    }
  }
}

.filters {
  @media (max-width: 1090px) {
    display: flex;
    flex-direction: column;
  }
}

.title {
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 16px !important;

  @media (max-width: 1090px) {
    text-align: center;
  }
}

.flexColumn {
  padding-bottom: 22px;
  padding: 33px 17px;

  &:first-child {
    @media (min-width: 1090px) {
      border-bottom: 1px solid #bebebe;
    }
    @media (max-width: 767px) {
      border-bottom: 1px solid #bebebe;
    }
  }
}

.offer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  background: #fff;
  border: 2px solid #ebeef2;
  z-index: 50;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 5px 10px;

  @media (max-width: 400px) {
    width: 100%;
  }
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.flex {
  display: flex;
}

.button {
  padding: 18px 33px 21px;
  background: #e74137;
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  white-space: nowrap;

  &:hover {
    color: #fff;
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }

  @media (max-width: 420px) {
    font-size: 14px;
    padding: 18px 22px 21px;
  }
}

.buttonWrapper {
    margin-top: 50px;
    margin-bottom: 30px;
}
.trust-carousel-contaier {
  max-width: 1100px;
  padding: 0 15px;
  margin: 100px auto;
  position: relative;
}

.HomePage {
  overflow-x: hidden;
}

.trust-carousel {
  margin: 0 auto;
}

.trust-label {
  font-family: "Raleway";
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #e74137;
  padding: 0 0 2em;
  font-weight: 700;
}

.react-multiple-carousel__arrow--left {
  left: -45px !important;
  background: none !important;
}

.react-multiple-carousel__arrow--right {
  right: -45px !important;
  background: none !important;
}

.react-multiple-carousel__arrow--left:before,
.react-multiple-carousel__arrow--right:before {
  color: #000 !important;
  font-size: 28px !important;
}

.react-multi-carousel-item {
  width: auto !important;
  padding: 0 15px;
}

/* swiper */

.swiper {
  width: 90%;
  height: 100%;
  padding: 0 10px !important;
}

.custom_homepage_swiper .swiper {
  width: 100%;
}

.custom_homepage_swiper .swiper-pagination {
  display: inline-flex;
  background: #fff;
  width: 121px;
  padding: 15px;
  border-radius: 20px;
  margin-left: 70px;
  bottom: 20px;
}

.custom_homepage_swiper .swiper-pagination .swiper-pagination-bullet {
  color: #000 !important;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: auto;
  padding: 0 10px;
}

.swiper-slide .image.modify {
  margin-bottom: 15px;
}

@media (max-width: 500px) {
  .swiper-slide .image.modify {
    margin-bottom: 17px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

.swiper-arrows .swiper-button-prev,
.swiper-arrows .swiper-button-next {
  display: block !important;
  top: 50%;
}

.swiper-arrows .swiper-button-prev {
  color: #343753;
}

.swiper-arrows .swiper-button-next {
  right: 10px;
  color: #343753;
}

.swiper-arrows .swiper-button-prev.swiper-button-disabled,
.swiper-arrows .swiper-button-next.swiper-button-disabled {
  color: #a0c1f3;
}

.newsItem {
  display: flex;
  cursor: pointer;
  margin-bottom: 25px;
  padding: 10px;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;

  @media (min-width: 1101px) {
    &:first-child {
      margin-top: 80px;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 5px;
    border-bottom: 1px solid #e74137;
  }

  & > img {
    height: 150px;
    margin-right: 20px;
  }

  @media (max-width: 550px) {
    flex-direction: column;

    & > img {
      margin-bottom: 30px;
      height: auto;
      width: 100%;
    }
  }
}

.title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #343753;
  text-align: left;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paragraph {
  text-align: left;
  -webkit-line-clamp: 4; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #000000;
}

.date {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #000000;
}

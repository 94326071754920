a {
  text-decoration: none;
}

.policy-footer-link {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: inherit;
    opacity: 0.8;
    text-decoration: underline;
  }
}

.footer-images {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 24px 0;

  @media (max-width: 660px) {
    justify-content: center;
  }
}

.footer-images img {
  @media (max-width: 1200px) {
    margin: 8px auto;
  }

  @media (max-width:  1000px) {
    padding: 0 4px;
  }
}

.company {
  color: #e74137 !important;
  font-weight: 700;
}

.belcable-history {
  /* font-family: 'Raleway'; */
  font-size: 16px;
  line-height: 24px;text-align: justify;
}

.footer-links {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 19px;
}

@media (max-width: 879px) {
  .footer-logo {
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .footer-logo {
    width: 250px;
  }
}

.main-footer-wrapper > .container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px 3rem;
}

.footer-first-child {
  margin: 0 !important;
}

.unique-links {
  color: inherit;

  &:hover {
    color :inherit;
    text-decoration: underline;
  }
}
.footer-social a{
  margin-right: 15px;
}
.footer-social img{
  max-height: 40px;
}
.contacts-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

@media (max-width: 952px) {
  .contacts-page-info {
    display: flex;
    flex-wrap: wrap;
  }

  .contacts-page-info > * {
    flex: 0 0 50%;
    margin-bottom: 15px;
  }
}

@media (max-width: 550px) {
  .contacts-page-info {
    display: block;
  }

  .contacts-page-info > * {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }
}

.contacts-page-info p {
  font-size: 18px;
  line-height: 21px;
  color: black;
}

.contacts-row {
  margin-top: 3%;
  display: flex;
}

.contacts-row__wrapper div {
  display: flex;
  flex-wrap: wrap;
}

.contacts-row__wrapper div > div {
  flex: 0 0 33%;
}

.contacts-row__wrapper div > div > p {
  width: 100%
}

.contacts-row__wrapper div > div > p > *{
  display: block;
  margin: 0;
}

.contacts__wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
}

.feedback-button {
  background: #e74137;
  border-radius: 50px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  border-style: none;
  padding: 1em 2em;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.feedback-button:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
}

.feedback-button:hover {
  background: #fd3e34;
}

.feedback-button:active {
  background: #ad2922;
}

.feedback-button-container {
  padding: 2em 0;
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  .contacts-page-info p {
    font-size: 1.2em;
    color: black;
  }
}

.contactsMap > div {
  width: 550px !important;
}

@media (max-width: 1200px) {
  .contactsMap > div {
    width: 600px !important;
  }
}

@media screen and (max-width: 952px) {
  .contacts-row {
    display: block;
  }

  .contactsMap {
    width: 100%;
  }

  .contactsMap > div {
    width: 100% !important;
  }
}

.svg__call-icon img {
  width: 20px;
}
.flex {
  background: #ffffff;
  border-radius: 15px;
  padding: 20px 25px;
  margin-bottom: 28px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
  }
}

.img {
  background: #d9d9d9;
  border-radius: 50%;
  padding: 5px;
  transform: rotateX(-180deg);
  transition: all 0.3s ease;
  cursor: pointer;

  &.active {
    transform: rotateX(0);
    background: #eaa023;
  }
}

.paragraph {
  font-size: 16px;
  line-height: 19px;
  margin-top: 0 !important;
  color: #5e5a5a;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  max-height: 0;

  &.active {
    opacity: 1;
    visibility: visible;
    margin-top: 15px !important;
  }
}

.flex {
  display: flex;
  border-bottom: 2px solid #e74137;
  background: #fff;
  padding-top: 2px;
  border-radius: 4px;
}

.searchIcon {
  width: 28px;
  height: 29px;
}

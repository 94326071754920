.press-center-page-conteiner {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.press-center-page-row {
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
}

.press-center-page-row > * {
  flex: 0 0 32%;
  margin-bottom: 30px;
  margin-right: 1%;

  font-family: "Raleway";
  font-feature-settings: "pnum" on, "lnum" on;
}

.press-center-page-col {
  margin-top: 20px;
}

.press-center-photo {
  width: 100%;
  object-fit: cover;
  transform: translate3d(0, 0, 1px);
}

.default-photo-styles {
  object-fit: contain;
}

.press-center {
  border-radius: 5px;
  padding: 29px 15px 45px;
  text-align: center;
  transition: all 0.3s ease;
}

.press-center > * {
  height: 50%;
}

.press-center > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.press-center-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.press-center-wrapper > p {
  margin-top: 20px;
}

.press-center-button {
  background: #e74137;
  padding: 19px 63px;
  border: none;
  border-radius: 50px;
  color: #fff;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.press-center-button:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
}

.press-center:hover .read-button {
  display: inline-block;
}

.press-center-name {
  text-transform: uppercase !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #343753;
}

.press-center:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

.info-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "pnum" on, "lnum" on;
  text-align: left;
}

.read-button {
  display: none;
  background: #e74137;
  border-color: #e74137;
  padding: 1em 3em;
  border-radius: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  border-style: none;
}

.read-button:hover {
  background: #ff493f;
  border-color: #ff493f;
}

.read-button:active {
  background: #aa2d26;
  border-color: #aa2d26;
}

.read-button:disabled {
  background: #e74137;
  border-color: #e74137;
}

@media screen and (max-width: 1100px) {
  .press-center-page-row {
    display: block;
  }
  .press-center-page-col {
    flex: 0;
    width: 100%;
  }

  .press-center-photo {
    width: 50%;
  }
}
@media screen and (max-width: 550px) {
  .press-center-photo {
    width: 100%;
  }
}

.press-center-main-text {
  flex: 0 0 31%;
}

.container {
     max-width: 1130px;
    margin: 0 auto;
    padding: 25px 15px;
}

.preTitle {
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 10px !important;
  font-family: 'Roboto';
}

.title {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 20px !important;
  font-family: 'Roboto';
}

.paragraph {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  &.margin {
    margin-top: 20px !important;
    margin-bottom: 58px !important;
  }
}

.oldPrice {
  font-size: 15px;
  line-height: 18px;
  color: #a9a9a9;
  display: flex;
  align-items: center;
  margin-bottom: 4px !important;

  & > span {
    padding: 5px;
    font-size: 8px;
    line-height: 9px;
    color: #ffffff;
    background: #eb5757;
    border-radius: 30px;
    margin-left: 10px;
  }
}

.button {
  margin-top: 15px;
  margin-bottom: 67px;
  & > button {
    padding: 11px 41px;
    color: #fff;
    border: none;
    background: #eaa023;
    border-radius: 30px;
    transition: all 0.3s ease;
    &:hover {
      filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
    }
  }

  &.active {
    margin-bottom: 30px;
  }
}

.italic {
  font-style: italic;
  font-weight: 700;
}

.decoration {
  text-decoration: underline;
}

.center {
  display: block;
  text-align: center;
}

.flex {
  display: flex;
  margin-bottom: 43px;
  flex-wrap: wrap;
  margin-top: 20px;

  & > * {
    flex: 0 0 50%;
  }

  & > *:first-child {
    padding-right: 23px;
    box-sizing: border-box;
  }

  @media (max-width: 767px) {
    & > * {
      flex: 0 0 100%;
    }

    & > *:first-child {
      margin-bottom: 20px;
    }
  }
}

.cards {
  margin-top: 31px;
  display: flex;
  gap: 18px;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.wrapper {
  position: relative;
  margin-bottom: 45px;
}

.card {
  border-radius: 20px 20px 40px 20px;
  background: #fff;
  width: calc(32% - 18px);
  padding: 23px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 767px) {
    width: calc(49% - 18px);
  }

  @media (max-width: 550px) {
    width: 100%;
  }
}

.image {
  margin: 0 auto 0;
}

.price {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  font-weight: 700;
  text-align: left;
}

.name {
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  text-align: left;
  color: #000000;
}

.flexCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  & a {
    color: #000;
    font-size: 13px;
    line-height: 15px;
    padding: 9px 25px;
    border: 1px solid #bebebe;
    border-radius: 30px;
    transition: all 0.3s ease;

    &:hover {
      background: #eaa023;
      color: #fff;
      border: 1px solid transparent;
    }
  }
}

.img {
  height: 24px;
  padding: 11px;
  cursor: pointer;
  box-sizing: content-box;
  border: 1px solid #bebebe;
  border-radius: 50%;
  transition: all 0.3s ease;

  & svg {
    padding: 0;
    margin-top: -3px;
    stroke: #423f3f;
    transition: all 0.3s ease;
  }

  &:hover svg {
    stroke: #fff;
  }

  &:hover {
    background: #eaa023;
    border: 1px solid transparent;
  }
}

.logoImage {
  text-align: center;
  margin-bottom: 24px !important;

  & > img {
    width: 100%;
  }
}

.link {
  text-align: center;
  margin-bottom: 68px !important;

  & > * {
    background: #eaa023;
    border-radius: 30px;
    padding: 12px 60px;
    color: #fff;
    transition: all 0.3s ease;

    &:hover {
      filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
    }

    &:hover {
      color: #fff;
    }
  }
}

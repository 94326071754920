.main-slider-swiper .swiper-slide {
  background: transparent;
  color: #fff;
  display: block;
  min-height: 500px;
}

.main-slider-swiper .swiper-slide img {
  max-width: 100% !important;
}

@media (max-width: 500px) {
  .main-slider-swiper .swiper-slide {
    min-height: fit-content;
  }
}

@media (max-width: 992px) {
  .main-slider-swiper .swiper-slide {
    display: flex;
    flex-direction: column;
  }
}

.main-slider-swiper .swiper-slide p {
  text-align: left;
}

.main-slider-swiper .swiper {
  padding: 0 !important;
}

.main-slider-swiper .swiper-pagination {
  text-align: left;
  bottom: 35px;
}

.main-slider-swiper .swiper-pagination-bullet {
  background: transparent;
  color: #d9d9d9;
  margin-right: 25px !important;
  width: auto;
  height: auto;
}

.main-slider-swiper .swiper-pagination-bullet-active {
  position: relative;
}

.main-slider-swiper .swiper-pagination-bullet-active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background: #fff;
  border-radius: 50%;
}

.swiper-button-prev.arrow,
.swiper-button-next.arrow {
  top: 50%;
  color: #295af0;
  background: #fff;
  box-sizing: content-box;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.swiper-button-prev.arrow {
  left: -10px;
}

.custom_homepage_swiper .swiper-button-prev.arrow {
  left: 10px;
}

.swiper-button-next.arrow {
  right: -10px;
}

.swiper-button-prev.arrow::after,
.swiper-button-next.arrow::after {
  font-size: 20px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@media (max-width: 1150px) {
  .swiper-button-prev.arrow {
    left: 0;
  }

  .swiper-button-next.arrow {
    right: 0;
  }
}

@media (max-width: 767px) {
  .main-slider-swiper .swiper-pagination {
    display: none;
  }
}

.swiper-slide img {
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.wrapper {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  & > * {
    flex: 0 0 calc(25% - 15px);

    & > * {
      width: 100%;
    }

    @media (max-width: 900px) {
      flex: 0 0 calc(33% - 15px);
    }

    @media (max-width: 700px) {
      flex: 0 0 calc(50% - 15px);
    }

    @media (max-width: 450px) {
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 450px) {
    gap: 0;
  }
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;

  & p {
    margin: 0;
  }
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
}

.work {
  font-family: 'Raleway';
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-bottom: 80px;
}

.vacancy {
  margin-top: 40px;
  margin-bottom: 80px;
}

.documentation-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.documentation-page-row {
  margin-top: 5%;
  justify-content: space-between;
}

.documentation-page-link {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 26px;
  text-decoration-line: underline;
  color: #343753;
}

.banner {
  padding: 28px 0 0;
  max-width: 1300px;
  margin: 0 auto;
}

.banner div {
  margin: auto 0;
  color: black;
}

@media (max-width: 1300px) {
  .banner {
    padding: 24px 0 0;
  }
}

@media screen and (max-width: 1073px) {
  .icons {
    max-width: 400px !important;
    margin: 0 auto 0px auto !important;
  }
}

@media screen and (max-width: 779px) {
  .sectionReception {
    margin: 40px 0px 0px 70px !important;
  }
  .sectionSales {
    margin: 40px 0px 0px 70px !important;
  }
  .icons {
    margin: 40px 0px 0px auto !important;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    text-align: center;
  }
  .icons {
    margin: 40px 0px 0px 0px !important;
  }
}

.company-button {
  border: none;
  font: inherit;
  color: rgba(0, 0, 0, 0.55);
  background-color: transparent;
  height: 100% !important;
  padding: 0.5rem 0.5rem;
}

.company-button:hover {
  color: black;
  border: none;
  background-color: transparent;
}

.company-button:focus {
  color: black;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.company-button:active {
  color: black;
  outline: none;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

nav.navbar-expand-lg.navbar-light div.container button.navbar-toggler {
  border: none;
}

.header-nav {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

nav.navbar-expand-lg.navbar-light div.container button {
  margin: 0 auto;
}

.autorize-link {
  text-transform: uppercase;
  color: black;
  font-size: 18px;
}

.register-link {
  text-transform: uppercase;
  color: black;
  font-size: 18px;
}

.autorize-link:hover,
.register-link:hover {
  color: #e74137;
  text-decoration: underline;
}

.authorize-window-body {
  padding: 1em 5em;
  text-align: center;
}

.authorize-button-container {
  padding: 1em 0em;
}

.authorize-button {
  background-color: #e74137;
  border: none;
  color: #ffffff;
  padding: 19px 54px;
  border-radius: 50px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.authorize-button:hover {
  background-color: #e74137;
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
}

@media screen and (max-width: 464px) {
  nav.navbar-expand-lg.navbar-light div.container button {
    margin: 0;
  }
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
  .navbar-collapse {
    margin-top: 20px;
    text-align: center;
  }
}

.authorize-tabs {
  margin-top: 30px;
  padding: 0em 4em;
}

.authorize-tabs .nav-link,
.authorize-tabs .nav-link-active {
  border: none;
  outline: none;
}

.authorize-tabs .nav-link {
  color: #212529;
  font-size: 18px;
}

.authorize-tabs .nav-link:hover {
  color: #e74137;
  text-decoration: underline;
}

.authorize-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #e74137;
}

.authorize-tabs .nav-link.active {
  background-color: #fff;
  color: #e74137;
}

.profile-button:focus {
  box-shadow: none;
}

.profile-button {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: inherit;
  text-decoration: none;
  padding: 0 10px;
}

.profile-button:hover {
  text-decoration: underline;
  color: inherit;
}

.authorize-close-button {
  border: none;
}

#dropdown-basic:hover .firstDropdown {
  display: block;
}

.logotext {
  line-height: 18px;
}

.container {
  position: relative;
}

.authorize-input {
  width: 100%;
  font-family: 'Raleway';
  padding: 10px 23px;
  border: 1px solid #343753;
  border-radius: 5px;
  outline: none;
}

.authorize-input::placeholder {
  color: #343753;
}

.nav-item > button {
  font-family: 'Raleway';
  font-weight: 900;
}

@media (max-width: 576px) {
  .authorize-window-body {
    padding: 15px;
  }
}

.search-container {
  max-width: 1300px;
  padding: 10px 15px;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
}

.search-image {
  width: 98%;
}

.search-container-wrapper {
  background: #cddbec;
  padding-top: 1px;
}

.search-inputs {
  padding: 0 15px;
  margin: 40px auto 0;
  font-size: 24px;
  transform: translateY(-50%);
  display: flex;
}

.search-inputs > *:first-child {
  flex: 0 0 75%;
  padding-bottom: 5px;
  align-items: center;
}

.search-inputs > *:last-child {
  flex: 0 0 25%;
}

@media (max-width: 767px) {
  .search-inputs {
    flex-direction: column;
  }

  .search-inputs > *:first-child {
    margin: 50px 0 10px;
    flex: 0 0 100%;
  }

  .search-inputs > *:last-child {
    flex: 0 0 100%;
    padding: 0;
  }
}

.search-button {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #e74137;
  border: 2px solid #e74137;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.search-button:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
}

.seacrh-text-block {
  width: 100%;
  height: 100%;
  background: none;
  padding: 0 1em;
  color: #212529;
  border: none;
}

.seacrh-text-block:active,
.seacrh-text-block:focus {
  outline: none;
}

@media (max-width: 992px) {
  .search-container {
    margin-top: 50px;
  }
  .search-inputs {
    top: 17%;
  }

  .search-inputs > * {
    font-size: 20px;
  }
}

@media screen and (max-width: 786px) {
  .search-image {
    width: 1200px;
    margin-left: -7px;
    object-fit: cover;
  }
  .search-container {
    width: 100%;
  }
}

@media screen and (max-width: 458px) {
  .search-inputs > * {
    padding-right: 0;
    padding-left: 20px;
  }
  .search-image {
    width: 1200px;
    margin-left: -7px;
    object-fit: cover;
  }
  .search-container {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .search-inputs > * {
    padding-left: 20px;
  }
  .seacrh-text-block {
    padding: 0;
  }
}

.product-remaining {
  font-size: 24px;
  line-height: 28px;
  margin-left: 6px;
}

.product-carousel-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 80px auto 0;
}

.product-carousel .carousel-indicators {
  justify-content: center !important;
  background: none;
  backdrop-filter: none;
}

.product-carousel .carousel-indicators button {
  width: 23px !important;
  border-radius: 200px;
  background: #343753 !important;
}

.product-carousel .carousel-item {
  padding: 0em 0;
}

.product-carousel .carousel-item .row {
  /* padding: 0em 0em 6em 0em; */
  width: 100%;
  margin: 0 auto;
}

.product-carousel .card-body {
  text-align: center;
}

.product-carousel .carousel-control-prev-icon {
  display: none;
}

.product-carousel .carousel-control-next-icon {
  display: none;
}

.catalogButton {
  width: 100% !important;
  text-align: center;
}
.catalogButton .cB {
  background: #e74137;
  border-radius: 50px !important;
  margin-top: 100px;
  width: 298px;
  height: 95px;
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
}

.productLabel {
  text-align: center;
  width: 100%;
  color: #e74137;
  font-family: 'Raleway';
  font-size: 36px;
  line-height: 43px;
}

.product-carousel-card {
  z-index: 0;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.round-image {
  /* padding: 1em; */
  margin-top: 20px;
}

.product-carousel-card:hover .card-title {
  color: #e74137;
  text-align: center;
  display: block;
}

.product-carousel-card:hover .product-carousel-round {
  transform: translate(-62%, 1.5%) rotate(20deg);
}

.product-carousel-card-body .card-title {
  transition: all 0.3s ease;
  text-align: justify;
}

.product-carousel-card-body .card-text {
  font-family: 'Raleway';
  text-align: justify;
}

.product-carousel-row {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.product-carousel-card-body {
  padding: 1rem 1em;
}

.product-carousel-card-body > p:last-child {
  text-align: center;
}

.product-carousel-col {
  flex: 0 0 32%;
  margin-left: 1%;
  text-align: center;
  transition: all 0.3s ease;
  margin-bottom: 20px;
  cursor: pointer;
}

.product-carousel-col.modify {
  flex: 0 0 24%;
}

.product-carousel-col:hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

@media screen and (max-width: 1280px) {
  .round-image {
    width: 140px;
  }
}

@media (max-width: 880px) {
  .product-carousel-col {
    flex: 0 0 49%;
  }
}

@media (max-width: 690px) {
  .product-carousel-col {
    flex: 0 0 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 602px) {
  .catalogButton .cB {
    width: 278px;
    height: 75px;
    font-size: 16px;
    margin-top: 80px;
  }
  .round-image {
    width: 120px;
  }
}

@media screen and (max-width: 421px) {
  .product-carousel-row {
    margin-left: 0;
  }
  .product-carousel-container {
    width: 100%;
    text-align: center;
  }
  .catalogButton .cB {
    width: 258px;
    height: 55px;
    font-size: 12px;
    margin-top: 60px;
  }
  .round-image {
    width: 110px;
  }
}

.custom-button-product {
  background: #e74137;
  border-radius: 50px;
  padding: 18px 33px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin-top: 50px;
  display: inline-block;
  padding-top: 20px;
}

.custom-button-product:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  color: #fff;
}

.product-carousel-image {
  width: 190px;
  height: 190px;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  background: #cddbec;
}

.product-carousel-round {
  width: 190px;
  height: 190px;
  position: absolute;
  transform: translate(-53%, 8%) rotate(20deg);
  margin-top: 0;
  transition: all 0.3s ease;
}

.product-carousel-round-background {
  position: absolute;
  width: 190px;
  height: 190px;
  transform: translate(-53%, 8%) rotate(20deg);
}

.product-carousel-image::before {
  content: '';
  height: 174px;
  width: 174px;
  position: absolute;
  transform: translateX(-50%) rotate(20deg);
  border-radius: 50%;
  z-index: -1;
  background: #cddbec;
}

.product-carousel-image.modify::before {
  transform: translateX(0);
}

.fig {
  position: relative;
  height: 190px;
  width: 190px;
  margin: 0 auto;
}

.fig::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background-color: #cddbec;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(-7%, 7%) rotate(-34deg) scale(1.2);
  transition: transform 500ms;
}

.img_default {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 500ms;
  transform: translate(-7%, 7%) rotate(61deg) scale(1.5);
}

.clip {
  position: absolute;
  inset: 0;
  clip-path: inset(0 -100% 0 0 round 50%);
  overflow: hidden;
}

.back {
  position: absolute;
  inset: 0;
  transform: rotate(-45deg);
}

.product-carousel-card:hover .img {
  transform: translate(10%, 0%) rotate(-34deg) scale(1.5);
}

.product-carousel-card:hover .img_default {
  transform: translate(10%, 0%) rotate(61deg) scale(1.5);
}

.img_mod_2 {
  transform: translate(-7%, 7%) rotate(-34deg) scale(1);
}

.product-carousel-card:hover .img_mod_2 {
  transform: translate(4%, 0%) rotate(-34deg) scale(1.05);
}

.img_mod_3 {
  transform: translate(-5%, 5%) rotate(-34deg) scale(1);
}

.product-carousel-card:hover .img_mod_3 {
  transform: translate(4%, 0%) rotate(-34deg) scale(1.05);
}

.img_mod_4 {
  transform: translate(-5%, 5%) rotate(-50deg) scale(1.2);
}

.product-carousel-card:hover .img_mod_4 {
  transform: translate(5%, -1%) rotate(-50deg) scale(1.2);
}

.img_mod_5 {
  transform: translate(-5%, 5%) rotate(-50deg) scale(1.2);
}

.product-carousel-card:hover .img_mod_5 {
  transform: translate(5%, -1%) rotate(-50deg) scale(1.2);
}

.img_mod_6 {
  transform: translate(-10%, 9%) rotate(-34deg) scale(1.1);
}

.product-carousel-card:hover .img_mod_6 {
  transform: translate(2%, 2%) rotate(-34deg) scale(1.15);
}

.img_mod_7 {
  transform: translate(-5%, -6%) rotate(-40deg) scale(1.1);
}

.product-carousel-card:hover .img_mod_7 {
  transform: translate(6%, -10%) rotate(-40deg) scale(1.2);
}
.table {
  border: 1px solid black;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  & > tbody > *:not(:first-child) {
    border-top: 1px solid black;
  }
}

.tableItem {
  & > * {
    padding: 10px;
  }

  & > *:first-child {
    border-right: 1px solid #000;
    width: 20%;
  }
}

.dillersTitle{
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  color: #e74137;
}

.wrapper-question {
  width: 100%;
}
.second-banner-container {
  position: relative;
  margin-top: 70px;
  background-size: cover;
  background-image: url('../../hpBanner2-s.jpg');
  background-position: center;
}

.second-banner-wrapper {
  padding: 25px 0px;
}

.second-banner-image {
  position: relative;
  width: 100%;
}

.second-banner-info {
  max-width: 1300px;
  margin: 0 auto;
  padding-left: 17%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.second-banner-label {
  color: #e74137 !important;
  font-family: 'Raleway';
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  margin-top: 30px;
  font-weight: 700;
}

.label-amount {
  color: #e74137;
  font-family: 'Raleway';
  font-size: 32px;
  line-height: 1.5;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-align: center;
    img{
      max-width: 100%;    
      max-height: 45px;
    }
}

.label-value {
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  width: 200px;
  margin-top: 4px;
}

.second-banner-common-grid {
  margin-top: 43px;
  flex-wrap: wrap;
}

.second-banner-common-grid > * {
  flex: 0 0 20%;
  margin-bottom: 30px;
  width: 20%;
  padding: 0px 15px;
}

.value-description {
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
  text-align: center;
  padding-right: 10px;
}

.second-banner-common-grid {
  display: flex;
  align-items: center;
  height: 100%;
  align-items: flex-start;
  margin: 45px -15px 0px -15px;
}

@media (min-width: 1101px) {
  .second-banner-wrapper.mobile {
    display: none;
  }
}

@media screen and (max-width: 1672px) {
  .second-banner-label {
    font-size: 34px;
    line-height: 40px;
  }

  .label-amount {
    font-size: 30px;
  }

  .label-value {
    font-size: 16px;
    line-height: 19px;
    width: 200px;
    margin-top: 2px;
  }

  .second-banner-common-grid {
    margin-top: 30px;
    align-items: flex-start;
  }

  .value-description {
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
  }
}

@media (max-width: 1500px) {
  .second-banner-info {
    padding-left: 20%;
  }
}

@media screen and (max-width: 1400px) {
  .second-banner-label {
    line-height: 38px;
    margin-top: 24px;
  }

  .label-amount {
    font-size: 30px;
  }

  .second-banner-common-grid {
    margin-top: 18px;
    align-items: flex-start;
  }

  .label-value {
    font-size: 16px;
    line-height: 17px;
  }

  .value-description {
    font-size: 12px;
    line-height: 12px;
    margin-top: 6px;
  }
}

@media screen and (max-width: 1200px) {
  .second-banner-label {
    font-size: 32px;
    line-height: 34px;
    margin-top: 10px;
  }

  .label-amount {
    font-size: 24px;
  }

  .label-value {
    font-size: 14px;
    line-height: 14px;
  }

  .second-banner-common-grid {
    margin-top: 10px;
    align-items: flex-start;
  }

  .value-description {
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (max-width: 1100px) {
  .second-banner-wrapper {
    position: static;
    display: flex;
    height: auto;
    background: #f4f9ff;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 80px;
  }

  .second-banner-info {
    padding-left: 0;
    width: 100%;
    margin-top: 0;
    display: flex;
    align-items: center;
  }

  .second-banner-common-grid {
    display: flex;
    justify-content: space-between;
  }

  .value-description {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  .second-banner-common-grid > * {
    text-align: center;
    flex: 0 0 25%;
    width: 25%;
  }

  .label-value,
  .value-description {
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 778px) {
  .second-banner-label {
    margin-top: 20px;
  }
  .second-banner-common-grid > * {
    flex: 0 0 33.333%;
    width: 33.333%;
  }  
}

@media (max-width: 700px) {
  .second-banner-wrapper {
    margin-top: -50px;
  }
}

@media (max-width: 670px) {
  .second-banner-common-grid > * {
    flex: 0 0 49%;
    margin-bottom: 30px;
  }

}

@media (max-width: 450px) {
  .second-banner-common-grid > * {
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
}

.input {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  outline: none;
  cursor: pointer;
  padding: 9px 13px;
  position: relative;
  z-index: 5;

  &.last {
    margin-bottom: 16px;
  }
}

.wrapper {
  position: relative;
  cursor: pointer;
}

.arrow {
  position: absolute;
  right: 15px;
  top: calc(50% - 19px);
  height: 6px;
  z-index: 5;

  & svg {
    transform: rotateX(0);
    transition: all 0.3s ease;
  }

  &.active svg {
    transform: rotateX(180deg);
  }
}

.select {
  position: absolute;
  top: 95%;
  width: 240px;
  max-height: 300px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #bebebe;
  overflow: auto;
  z-index: 15;
}

.selectItem {
  padding: 10px;
  transition: all 0.3s ease;
  box-sizing: content-box;

  &:not(:last-child) {
    border-bottom: 1px solid #bebebe;
  }

  &:hover {
    text-decoration: underline;
  }
}

.picture {
  object-fit: cover;
  width: 100vw;
  height: 100%;
  z-index: -1;

  @media (max-width: 992px) {
    width: auto;
  }
}

.wrapper {
  position: relative;
  min-height: 500px;

  @media (max-width: 500px) {
    min-height: fit-content;
  }
}

.swiper {
  max-width: 1090px;
  position: relative;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 1150px) {
    max-width: calc(100% - 30px);
    padding: 0 25px;
  }

  @media (max-width: 600px) {
    padding: 0 35px;
  }

  & .swiper-slide {
    max-width: 1040px;
    margin: 0 auto;
  }

  & .swiper {
    width: 100%;
  }
}

.swiperWrap {
  max-width: 1070px;
  margin: 0 auto;
  padding: 0 60px;
}

.title {
  font-size: 40px;
  line-height: 47px;
  margin-top: 107px;
  margin-bottom: 30px;
  width: 60%;

  @media (max-width: 992px) {
    text-align: center !important;
    width: 100%;
    margin-top: 30px;
  }

  @media (max-width: 500px) {
    word-break: break-all;
    font-size: 26px;
    line-height: 1.2;
  }

  &.modify {
    font-size: 30px;
    margin-top: 70px;
  }
}

.paragraph {
  font-size: 24px;
  line-height: 28px;
  color: #d9d9d9;
  width: 50%;

  @media (max-width: 992px) {
    width: 100%;
    text-align: center !important;
  }

  @media (max-width: 500px) {
    word-break: break-all;
    font-size: 16px;
    line-height: 1.2;
  }
}

.button {
  display: flex;
  margin-top: 30px;
  margin-bottom: 90px;

  & a {
    font-size: 20px;
    line-height: 23px;
    color: #000;
    padding: 15px 49px;
    background: #ffffff;
    border-radius: 30px;
  }

  &.unique a {
    background: #EAA023;
    color: #fff;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }

  @media (max-width: 400px) {
    width: 100%;

    & a {
      padding: 15px 0;
      width: 100%;
    }
  }
}

.swiperWrapper {
  background-size: cover;
  min-height: 500px;
  padding-top: 50px;

  @media (max-width: 500px) {
    min-height: fit-content;
  }
}

.slideBackground {    
  max-width: 100%;
  object-position: center;
  object-fit: cover;
  height: calc(100% + 30px);
  position: absolute;
  z-index: -1;
  top: 0;
  min-height: 500px;
  left: 0;
  padding: 0!important;
}

.cards {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.card {
  background: #FFFFFF;
  border-radius: 30px;
  color: #000;
  position: relative;
  padding: 5px 15px 5px 30px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;

  &:after {
    content: '';
    background: #EAA023;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 20;
    transform: translate(-50%, -50%);
  }
}

.black {
  color: #000
}

.btn {
  display: flex;
}
.project-page-conteiner {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.project-page-row {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.project-page-col {
  margin-top: 20px;
  flex: 0 0 32%;
}

.project {
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.project > img {
  width: 100%;
  margin-bottom: 28px;
}

.project-name {
  text-transform: uppercase !important;
  font-size: 0.8em;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #343753;
  margin-bottom: 0;
}

.project-name + p {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-top: 5px;
}

.project:hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

@media (max-width: 992px) {
  .project-page-col {
    flex: 0 0 50%;
  }
}

@media (max-width: 650px) {
  .project-page-col {
    flex: 0 0 100%;
  }
}

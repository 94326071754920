.quality-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.quality-page-info {
  margin-top: 5%;
}

.quality-page-info * {
  
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.quality-page-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.3em;
}

.quality-page-text span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26.18px;
}

.quality-page-row {
  margin-top: 0px;
}

.indentation-blocks {
  padding: 3em 0;
}

.quality-image-section {
  text-align: center;
}

.quality-page-row {
  justify-content: center;
}

.quality-page-images {
  width: 150px;
  height: 212.15;
}

.quality-page-info__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quality-page-info__wrapper:not(:last-child) {
  margin-bottom: 36px;
}

.quality-page-info__image {
  flex: 0 0 20%;
  padding-left: 35px;
}

.quality-page-info__image > img {
  height: 100%;
  max-width: 100%;
  max-height: 240px;
}

.quality-page-info__wrapper.reverse {
  flex-direction: row-reverse;
  padding: 0 35px;
}

.quality-page-info__image.reverse {
  padding-left: 0;
  padding-right: 35px;
}

@media (max-width: 650px) {
  .quality-page-info__wrapper.reverse,
  .quality-page-info__wrapper {
    flex-direction: column-reverse;
  }

  .quality-page-info__image.reverse,
  .quality-page-info__image {
    padding: 0;
  }

  .quality-page-info__wrapper.reverse {
    padding: 0;
  }
}

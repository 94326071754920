.defaultInput {
  border: 1px solid #343753;
  border-radius: 5px;
  width: 100%;
  padding: 18px 23px;
  font-size: 20px;
  line-height: 23px;
  color: #343753;
  margin-bottom: 14px;
  outline: none;
}

.button {
  background: #e74137;
  color: #fff;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  padding: 19px 53px;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 17px rgba(231, 65, 55, 0.51));
  }
}

.buttonWrapper {
  text-align: center;
  margin-bottom: 0;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1290px;
  background: #fff;
  margin: 0 15px;
  padding: 30px 15px;
  border-radius: 10px;

  @media (max-width: 1350px) {
    width: calc(100vw - 30px);
  }
}

.wrapper {
  width: 630px;
  margin: 0 auto !important;
  position: relative;
  padding: 0 15px;
  overflow-x: auto;
  max-height: 90vh;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.cross {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;

  &:after {
    content: '';
    width: 2px;
    border-radius: 2px;
    height: 100%;
    background: #343753;
    position: absolute;
    transform: rotate(-45deg);
  }

  &:before {
    content: '';
    width: 2px;
    border-radius: 2px;
    height: 100%;
    background: #343753;
    position: absolute;
    transform: rotate(45deg);
  }
}
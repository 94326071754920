.industry-list-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 80px auto 0;
}

.industry-list-row {
  margin-top: 5%;
}

.industry-label {
  height: 100%;
  margin-top: 5%;
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: #e74137;
  margin-bottom: 40px;
}

.custom-link {
  cursor: pointer;
}

/* =================================================== */

.industry-product-card-circle {
  transform: scale(0.95);
  transition: all 0.3s ease;
  height: 150px;
  z-index: 1;
  flex: 0 0 33%;
  margin-right: 15px;
  margin: 0 auto;
}

.industry-product-card-circle:first-child {
  left: 15px;
}

.industry-product-card {
  align-items: center;
  text-align: center;
  border: none;
  height: 177px;
  width: 100%;
  transition: all 0.3s ease;
  padding: 10px;
}

.industry-product-card > div {
  display: flex;
  justify-content: space-between;
}

.indestry-product-card-image {
  height: 100%;
}

.industry-product-card:hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

.industry-product-card:hover .industry-product-card-circle {
  transform: scale(1);
}

@media (max-width: 767px) {
  .industry-product-card {
    min-height: 250px;
    height: auto;
  }
}

.industry-list-col {
  padding-right: 2%;
}

.industry-list-col.col div.row {
  margin-top: 20px;
}

.product-name {
  font-family: 'Raleway';
  font-size: 20px;
  line-height: 23px;
}

.industry-product-card:hover .product-name {
  color: #e74137;
}

.industry-list-row {
  text-align: -webkit-center;
  margin-bottom: 60px;
}

@media screen and (max-width: 1124px) {
  .industry-list-row {
    display: block;
  }

  .industry-list-col {
    width: 83%;
  }

  .industry-product-card.card .row.row-cols-md-2 {
    margin: auto;
  }

  .card-body {
    margin: auto;
  }
  .industry-label {
    font-size: 28px;
  }
  .industry-product-card {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .industry-list-col {
    width: 100%;
    display: block;
  }
  .industry-product-card.card .row.row-cols-md-2 {
    display: block;
  }
  .card-body {
    padding: 2em 1em;
  }
}

@media screen and (max-width: 576px) {
  .industry-product-card-circle {
    height: 120px;
    float: left;
  }
  .industry-list-container {
    width: 96%;
  }
  .industry-list-col.col div.row {
    --bs-gutter-y: none;
  }
}

@media screen and (max-width: 444px) {
  .industry-product-card-circle {
    height: 100px;
    float: left;
  }
  .card-text:not(.modify) {
    display: none;
  }
  .industry-product-card {
    height: 110px;
  }
  .product-name {
    font-size: 14px;
  }
  .industry-label {
    font-size: 24px;
  }
}

/*------------------------- changed design ---------------------------*/

.product-card-body {
  margin: auto;
  flex: 0 0 65%;
  text-align: left;
}

@media (max-width: 767px) {
  .product-card-body {
    width: auto;
    margin: 0 auto;
  }

  .product-card-body > .card-body {
    text-align: center;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 43px;

  & > *:first-child {
    flex: 0 0 calc(70% - 24px);
  }

  & > *:last-child {
    flex: 0 0 30%;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    & > *:first-child {
      flex: 0 0 calc(100%);
      margin-bottom: 20px;
    }

    & > *:last-child {
      flex: 0 0 60%;
    }
  }
}

.flexItem {
  padding: 32px 34px;
  margin-top: 56px;
  background: #fff;
  border-radius: 30px;
}

.preTitle {
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #5e5a5a;
}

.title {
  font-size: 20px;
  line-height: 23px;

  color: #423f3f;
  margin-top: 12px;
}

.price {
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  width: 100%;
  margin-bottom: 15px !important;
}


.discount {
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #423f3f;
  margin-bottom: 26px !important;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #dcdcdc;
  border: 1px solid #d9d9d9;
  padding: 5px 12px;
  border-radius: 30px;
  margin-bottom: 17px;

  & > div:first-child {
    display: flex;
    align-items: center;
  }

  .amountRow {
      display: flex;
        align-items: center;

        span{
          margin: 0;
        }

        input {
          width: 50px;
          margin: 0;
        }
    }


  
}

.text {
  font-size: 10px;
  line-height: 12px;
  color: #5e5a5a;
}

.amountSum {
  font-size: 14px;
  line-height: 16px;
  color: #5e5a5a;
}

.flexDirection {
  margin-left: 10px;
}

.avatar {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 6px 7px;
  border-radius: 50%;
  background: #eaa023;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 30px;
  background: #eaa023;
  padding: 12px 0;
  margin-bottom: 30px;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
  }
}

.button span {
  color: #fff;
  margin-left: 7px;
}

.wrapper {
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: flex-start;
  color: #423f3f;
  margin-bottom: 18px;

  &.noMargin {
    margin-bottom: 0;
  }

  & img {
    width: 16.5px;
    height: 13.5px;
    margin-right: 10px;
    flex: 0 0 17px;
  }

  .amount-row {
      display: flex;
    align-items: center;

    span{
      margin: 0;
    }
}

}

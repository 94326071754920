.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #e74137;
  content: var(--bs-breadcrumb-divider, '>') !important;
}

.custom-breadcrumb .breadcrumb-item a {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #696763;
}

.custom-breadcrumb .breadcrumb-item.active {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #696763;
}

body {
  font-family: 'Raleway';
}

.hidden {
  overflow: hidden;
  height: 100vh;
}

.custom-breadcrumb .breadcrumb-item a:hover {
  color: #e74137;
}

.commonLabels {
  text-align: center;
  width: 100%;
  color: #e74137;
  font-family: 'Raleway';
  font-size: 36px;
  font-weight: 700 !important;
  line-height: 43px;
}

@media (max-width: 767px) {
  .commonLabels {
    font-size: 32px;
  }
}

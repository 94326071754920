.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: 1150px) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.paginationItem {
  padding: 7px;
  border: 1px solid #e4e5e5;
  border-radius: 2px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    color: #d83a2e;
    font-weight: bold;
    border-color: #d83a2e;
  }
}

.paginationLastItem {
  position: relative;
  padding: 7px;
  border: 1px solid #e4e5e5;
  border-radius: 2px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &.reverse {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    color: #d83a2e;
    font-weight: bold;
    border-color: #d83a2e;

    &:before {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZDgzYTJlIj48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiLz48L3N2Zz4=');
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-size: cover;
    width: 14px;
    height: 14px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjOTk5Ij48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiLz48L3N2Zz4=');
  }
}

.innovative-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.innovatives-block {
  margin-top: 2em;
  font-size: 18px;
  font-weight: 700;
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.cybersecurity-items{
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;
  gap: 15px;
}
.cybersecurity-item{
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(25% - 15px);
}

@media (max-width: 900px) {
  .cybersecurity-item{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33% - 15px);
  }  
}
@media (max-width: 768px) {
  .cybersecurity-item{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 15px);
  }  
}
@media (max-width: 480px) {
  .cybersecurity-item{
    flex: 0 0 100%;
    margin-bottom: 20px;
  }  
}
.containerWrapper {
  background: #fff;
}

.footerContainer {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px;
  color: #fff;
  font-family: 'Roboto';
  display: flex;

  & > *:first-child {
    flex: 0 0 50%;

    & > * {
      width: 70%;

      @media (max-width: 992px) {
        width: 100%;
      }
    }
  }

  & a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    margin: 0;
  }

  & ul {
    padding: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.flex {
  display: flex;
  flex: 0 0 50%;
  justify-content: space-between;

  @media (max-width: 992px) {
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    justify-content: center;
    margin-top: 30px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}

.menuFooter ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 300px;

  & > * {
    flex: 0 0 40%;
    margin-bottom: 13px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer {
  background: #eaa023;
  padding: 32px 0;
}

.pastFooter {
  background: #000000;
  color: #bebebe;
}

.counter {
  font-size: 12px;
  background: #e74137;
  border-radius: 50%;
  padding: 0 5px;
  color: #fff;
  margin-left: 5px;
}

.itemImageWrapper {
  position: relative;
  height: 18px;
  padding-right: 20px;
  margin-top: -13px !important;

  & > img {
    height: 18px;
  }

  @media (max-width: 1150px) {
    margin-top: -3px;
  }
}

.infoItem {
  margin-bottom: 10px;
}

.infoItem.personal {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & > span {
    margin-right: 5px;
  }
}

.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}

.paragraph {
  padding-top: 16px;
}

.preName {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px !important;
}

.infoFooter:not(.mobile) {
  @media (max-width: 992px) and (min-width: 768px) {
    display: none;
  }
}

.infoFooter.mobile {
  margin-top: 30px;
  @media (min-width: 992px) {
    display: none;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.pastFooterContainer {
  max-width: 1130px;
  margin: 0 auto;
  padding: 17px 15px;
  color: #bebebe;
  display: flex;

  & > span {
    margin-right: 91px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;

    & > span {
      margin-right: 0;
    }
  }
}

.address {
  display: flex;
  align-items: center;

  & > img {
    margin-right: 9px;
  }
}

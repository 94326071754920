.management-page-container {
  max-width: 1300px;
  margin: 0 auto 70px;
  padding: 0 15px;
}

.human {
  border-radius: 5px;
  padding: 1em;
  text-align: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.human-name {
  text-transform: uppercase !important;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.human:hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(40px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

.management-page-row {
  margin-top: 3%;
}

.management-page-col {
  margin-top: 20px;
}

@media screen and (max-width: 684px) {
  .management-page-row {
    display: block;
  }
}

.human-photo {
  transition: all 0.3s ease;
}

.human:hover .human-photo {
  transform: scale(1.03);
}

.card-text.card-modify {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
.parent {
  position: relative;
  margin-top: 80px;
  width: 100%;
  height: 294px;
}

.banner-lbl-wrapper {
  position: absolute;
  height: 294px;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
}

.banner-image-1 {
  display: inline-block;
  position: relative;
  width: 100%;
  z-index: -1;
  height: 294px;
  object-fit: cover;
}

.bannerlbl {
  z-index: 1;
  text-align: right;
  flex: 0 0 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner1-check {
  flex: 0 0 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannerlbl .lbl {
  color: #e74137 !important;
  font-family: 'Raleway';
  font-size: 32px;
  line-height: 43px;
  text-align: right;
  width: 100%;
}

.bannerText {
  font-family: 'Raleway';
  font-size: 32px;
  line-height: 42px;
  color: #343753;
  text-align: right;
  margin-bottom: 0px;
  width: 100%;
}

@media (max-width: 1700px) {
  .bannerText {
    font-size: 28px;
  }
  .bannerlbl {
    
  }
}

@media (max-width: 1400px) {
  .bannerlbl {
    
  }
}

@media screen and (max-width: 1300px) {
  .banner-image-1 {
 
  }
  .parent {
    
  }

  .bannerText {
    
  }
}

@media screen and (max-width: 1151px) {
  .bannerlbl {
    
  }
  .bannerText {
    
  }
  .bannerlbl .lbl {
    font-size: 28px;
    line-height: 30px;
  }

  .bannerText {
    font-size: 28px;
    line-height: 30px;
  } 

  .bannerlbl .lbl {
    
  }
}

@media (max-width: 992px) {
  .parent {
    height: auto;
  }
  .banner-lbl-wrapper {
    height: auto;    
  }
  .bannerlbl {
    flex: 0 0 100%;
  }  
  .banner1-check {
    flex: 0 0 100%;
    margin-top: 25px;
  }
  .bannerText {
    width: 100%;
  }

  .bannerlbl .lbl {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .bannerlbl .bannerText {
    text-align: center;
  }
  
  .banner-lbl-wrapper {
    position: static;
    background: #A0C1F3;
    padding: 20px 15px;
  }

  .banner-lbl {
    padding-top: 20px;
    text-align: center;
  }

  .banner-image-1 {
    display: none;
  }
}
@media (max-width: 768px) {
  .banner1-image-ckeck{
    max-height: 100px;
  }
}
.input {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  outline: none;
  padding: 9px 13px;

  &.last {
    margin-bottom: 16px;
  }
}

.form {
  margin-bottom: 20px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  align-items: flex-start;

  & .flexItem:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 0 0 calc(100%);
    align-items: flex-start;

    & > * {
      flex: 0 0 48%;
    }

    @media (max-width: 500px) {
      & > * {
        flex: 0 0 100%;
      }
    }
  }

  /*& .flexItem:last-child {
    flex: 0 0 32%;

    @media (max-width: 767px) {
      display: flex;

      & > p {
        margin-left: 15px !important;
      }
    }
  }*/

  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.input {
  height: 45.45px;
  background: #f8f8f8;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  padding: 0 13px;
  outline: none;
  margin-bottom: 20px;

  &::placeholder {
    font-size: 13px;
    line-height: 15px;

    color: #000000;
  }
}
.input.error {
  border: 1px solid #F00 !important;
}
.error {
  border: 1px solid #F00 !important;
}
.paragraph {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px !important;
  color: #000000;

  & span a {
    color: #eaa023;

    &:hover {
      text-decoration: underline;
    }
  }
}

.image {
  height: 26px;
  margin-bottom: 10px;
}

.logo-links-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
}

.logo-links-container > div {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo-links {
  transition: all 0.3s ease;
  color: black;
}

.logo-links:hover {
  color: #e74137;
}

.logo-link-wrapper {
  flex: 0 0 24%;
  margin: 20px 0;
}

@media (max-width: 992px) {
  .logo-link-wrapper {
    flex: 0 0 49%;
  }
}

@media (max-width: 500px) {
  .logo-link-wrapper {
    flex: 0 0 100%;
  }
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
  font-size: 16px;

  & * {
    line-height: 120%;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
}

.text {
  font-family: 'Raleway';
  margin-top: 50px;

  & > p:first-child {
    line-height: 210% !important;
  }

  & a {
    color: #d83a2e !important;
  }
}

.schedules {
  font-family: 'Raleway';
  margin-top: 40px;
  line-height: 150%;
  font-size: 18px;
}

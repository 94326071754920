.body {
  background: #eeedea;
  font-family: 'Roboto';
}

.container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px;
  padding-bottom: 50px;

  & p {
    margin: 0;
  }
}

.paragraph {
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 25px;
}

.table{
	width: 100%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
	border-collapse: collapse;
	white-space: nowrap;
}

.table th, .table td{
    text-align: center;
    padding: 15px 10px;
}

.table th{
    background-color: #eaa023;
    color: #fff;
}

.table th:nth-child(odd) {
    background: #eaa023;
}

.table tr:nth-child(even) {
    background: #eaa1235c;
}

.table td:not(:last-child){
    border-right: 1px solid #ccc;
}

.table-responsive{
	overflow-x: auto;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.input {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  outline: none;
  padding: 9px 13px;

  &.last {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.textarea {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  outline: none;
  padding: 9px 13px;
  min-height: 80px;
  margin-top: 16px;
  min-height: 180px;
}

.inputFile {
  display: none;
}

.inputFileButton {
  padding: 10px 24px 12px;
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    border: 1px solid #d4ecd7;
    background: #e5f7e7;
  }
}

.inputIconText {
  margin-top: 2px;
  margin-left: 4px;
  transition: all 0.3s ease;

  &.active {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #55ca62;
  }
}

.inputFileIcon {
  transition: all 0.3s ease;
  &.active {
    & > path {
      stroke: #55ca62 !important;
    }
  }
}

.save {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  padding: 12px 30px;
  margin-top: 15px;
  cursor: pointer;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.container {
  background: #fff;
  border-radius: 15px;
  padding: 24px 15px;
}

.margin {
  margin-top: 26px;
}

.sheet {
  &.active {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

.padding {
  margin-top: 20px;
}

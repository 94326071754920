.flex {
  display: flex;
  margin-bottom: 90px;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.flexItem {
  flex: 0 0 19%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1%;
  margin-bottom: 30px;

  & > img {
    width: 118px;
    height: 118px;
    margin: 0 auto;
  }

  & > p {
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin-top: 20px !important;
  }

  @media (max-width: 992px) {
    flex: 0 0 32%;
  }

  @media (max-width: 850px) {
    flex: 0 0 49%;
  }

  @media (max-width: 400px) {
    flex: 0 0 100%;
    margin-right: 0;
  }
}

.title {
  font-family: 'Roboto';
  font-size: 38px;
  line-height: 45px;
  color: #000000;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin-top: 50px;
  }
}

.calculator-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.calculator-container .info-label {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #343753;
}

.calculator-container .info-container {
  margin-top: 5%;
}

.calculator-container .info-container-row {
  margin-top: 5%;
}

.calculator-container .info-container-col {
  margin-top: 2%;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.calculator-container .info-container-col input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.calculator-container .info-container-col label {
  margin-left: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: inline-flex;
  user-select: none;
  position: relative;
  font-family: 'Raleway';
  word-break: break-all;
}

.calculator-container .info-container-col label:before {
  content: '';
  display: inline-block;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #343753;
  border-radius: 0.25em;
  margin-right: 0.5em;
  cursor: pointer;
}

.calculator-container .info-container-col input:checked + label:before {
  border-color: #343753;
  background-color: #cddbec;
}

.calculator-container .info-container-col input:checked + label:after {
  content: '';
  position: absolute;
  top: -5px;
  left: 6px;
  width: 19px;
  height: 28px;
  cursor: pointer;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxOSAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgMTIuMTExMUw2LjUyNSAyNUwxOCAxIiBzdHJva2U9IiNFNzQxMzciIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
}

.calculator-container .calculator-buttons {
  text-align: center;
  margin-top: 10%;
  gap: 64px;
  justify-content: center;
}

@media (max-width: 767px) {
  .calculator-container .calculator-buttons {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0;
  }

  .calculator-container .calculator-buttons > *:first-child {
    margin-bottom: 20px;
  }
}

.calculator-container .calculator-button {
  background: white;
  color: #e74137;
  border-radius: 50px;
  width: 269px;
  height: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #e74137;
  transition: all 0.3s ease;
}

.calculator-container .calculator-button:hover {
  background: #e74137;
  border-color: #e74137;
  color: rgb(255, 255, 255);
  border-radius: 50px;
}

.calculator-container .calculator-button:active {
  background: #af2720;
  border-color: #af2720;
  color: rgb(255, 255, 255);
  border-radius: 50px;
}

@media (max-width: 677px) {
  .calculator-container .button-col {
    margin-top: 5%;
  }
}

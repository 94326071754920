.title {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin: 27px 0 8px !important;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }

  & tr:first-child th {
    padding: 0 14px;
  }

  & tr:not(:first-child) > td {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & tr {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-bottom: 29px;

    & > * {
      padding: 0 8px;
    }

    & > *:first-child {
      flex: 0 0 35%;
    }

    & > *:not(:first-child) {
      flex: 0 0 calc((100% - 35%) / 3);
    }

    & > *:last-child {
      position: absolute;
    }
  }

  & tr:not(:first-child) {
    & > *:not(:nth-last-child(-n + 2)) {
      border-right: 1px solid rgba(217, 217, 217, 0.54);
    }
  }

  & th {
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: #423f3f;
  }

  & td:last-child {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 7px;
    background: #e3a8a8;
    border-radius: 5px;
    cursor: pointer;
  }
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 767px) {
    display: none;
  }

  & tr:first-child th {
    padding: 0 14px;
  }

  & tr:not(:first-child) > td {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & tr {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-bottom: 29px;

    & > * {
      padding: 0 8px;
    }

    & > *:first-child {
      flex: 0 0 35%;
    }

    & > *:not(:first-child) {
      flex: 0 0 calc((100% - 35%) / 3);
    }

    & > *:last-child {
      position: absolute;
    }
  }

  & tr:not(:first-child) {
    & > *:not(:nth-last-child(-n + 2)) {
      border-right: 1px solid rgba(217, 217, 217, 0.54);
    }
  }

  & th {
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: #423f3f;
  }

  & td:last-child {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 7px;
    background: #e3a8a8;
    border-radius: 5px;
    cursor: pointer;
  }
}

.tableImg {
  width: 10px;
}

.tableHeader {
  display: flex;
  align-items: center;

  & > *:last-child {
    margin-left: 5px;
  }
}

.flexCard {
  display: flex;
  align-items: center;
}

.cardTitle {
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  font-weight: 700;
  margin-left: 9px !important;
  font-family: 'Roboto';

  @media (max-width: 767px) {
    font-size: 24px;
    margin-left: 0 !important;
    line-height: 1.5;
  }
}

.paragraph {
  margin-top: 13px !important;
  font-size: 13px;
  line-height: 15px;

  color: #423f3f;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 1.5;
  }
}

.price {
  font-size: 17px;
  line-height: 20px;
  color: #000000;

  @media (max-width: 767px) {
    margin-right: 14px !important;
  }
}

.oldPrice {
  font-size: 17px;
  line-height: 20px;
  color: #a9a9a9;
  margin-right: 15px;

  @media (max-width: 767px) {
    margin-right: 5px !important;
  }
}

.discount {
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  color: #ffffff;
  background: #eb5757;
  border-radius: 30px;
  padding: 4px 7px;
}

.flexDiscount {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.itogo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span.title {
    margin-bottom: 16px !important;
  }
}

.img {
  width: 16.4px;
  height: 46.45px;
  padding: 3px 14px 0;
  border: 1px solid #bebebe;
  border-radius: 15px;
  position: relative;
  box-sizing: content-box;
}

.mobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    flex: 0 0 70%;
  }
}

.flexMobile {
  display: flex;
  align-items: center;
  width: 60%;

  & > div {
    margin-left: 20px;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  margin-top: 20px;
  font-size: 20px;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.flexMobile {
  display: flex;
  align-items: center;
  width: 60%;

  & > div {
    margin-left: 20px;
  }

  @media (max-width: 650px) {
    width: 100%;
  }
}

.mobileButton {
  width: 60%;
  border-radius: 30px;
  background: #eb5757;
  color: #fff;
  border: none;
  padding: 14px 0;
  margin-top: 20px;
  @media (max-width: 650px) {
    width: 100%;
  }
}

.mobile {
  margin-bottom: 30px;
  @media (min-width: 768px) {
    display: none;
  }
}

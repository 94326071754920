.flex {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  transition: all 0.3s ease;
  background: transparent;
  border-radius: 30px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &.active {
    background: #eaa023;
    border-radius: 30px;
  }

  & > span:first-child {
    display: flex;
  }
}

.text {
  margin-left: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #bebebe;
  transition: all 0.3s ease;

  &.active {
    color: #fff;
  }
}

.place-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.place-page-row {
  margin-top: 3%;
}

/* .place-page-image{
    width: 410px;
    height: 579px;
} */

.place-page-info p {
  font-size: 24px;
  color: black;
}

@media screen and (max-width: 972px) {
  .place-page-row {
    display: block;
  }

  .map > div {
    width: 100% !important;
  }

  .place-page-info {
    margin: 20px 0;
  }

  .place-page-info p {
    font-size: 18px;
  }
}

.map > div {
  width: 511px !important;
}

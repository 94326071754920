.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 47px;

  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 26px;
    text-decoration-line: underline;

    & > a {
      color: #343753;
      text-decoration: none;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.tabs {
  margin: 40px 0 40px 65px;

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  @media (max-width: 500px) {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }
}

.title {
  margin-left: 115px;
  font-family: 'Raleway';
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  color: #e74137;

  @media (max-width: 1200px) {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
  }
}

.cardContent {
  margin-left: 20px;
  width: 70%;
}

.tab {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  padding: 19px 23px;
  background: rgba(244, 249, 255, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 5px;

  cursor: pointer;
  transition: all 0.3s ease;

  &:last-child {
    border-radius: 0px 5px 5px 0px;
    position: relative;

    @media (min-width: 500px) {
      &:before {
        content: '';
        background: #cecece;
        border-radius: 5px;
        height: 80%;
        width: 1px;
        position: absolute;
        left: -0.5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &:first-child {
    border-radius: 5px 0px 0px 5px;
  }

  &:nth-child(2) {
    border-radius: 0;
  }

  &:hover {
    background: rgba(205, 219, 236, 0.9);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);

    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }
  }

  &.active {
    background: rgba(205, 219, 236, 0.9);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
  }
}

.dropdown {
  background: #f7fbff;
  border-radius: 5px;
  width: 100%;
  border: none;
}

.itemDropdown {
  white-space: normal;
  transition: all 0.3s ease;
  padding: 0 15px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    color: #e74137;
    background: none;
  }

  &.active {
    color: #e74137;
  }
}

.smallTabs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.smallTab {
  padding: 11px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(244, 249, 255, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(205, 219, 236, 0.9);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);

    &:last-child {
      border-radius: 0px 5px 5px 0px;
    }

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }
  }

  &.active {
    background: rgba(205, 219, 236, 0.9);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
  }

  @media (max-width: 500px) {
    width: 100%;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 70px;
}

.cardWrapper {
  text-align: center;
  margin-bottom: 20px;
  max-width: 30%;

  @media (max-width: 767px) {
    img {
      width: 120px;
    }
  }
  @media (max-width: 550px) {
    text-align: center;
  }
}

.card {
  margin-bottom: 26px;
  position: relative;
  transition: all 0.3s ease;
  padding: 21px 0 21px 1%;
  display: flex;
  justify-content: center;
  flex: 0 0 48%;
  overflow: hidden;
  cursor: pointer;

  &:not(.default) {
    flex: 0 0 31%;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 5px;

    &:before {
      background: #e74137;
    }

    .cardTitle {
      color: #e74137;
    }
  }

  @media (max-width: 767px) {
    flex: 0 0 100%;

    &:not(.default) {
      flex: 0 0 48%;
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 500px) {
    flex: 0 0 100%;
  }
}

.dropdown {
  position: relative;
}

.titleDropdown {
  padding: 5px 10px;
  background: #cddbec;
  border-radius: 5px;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.active {
    & img {
      transform: rotate(180deg);
    }
  }
}

.cardTitle {
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #343753;
  transition: all 0.3s ease;
  word-break: break-all;

  @media (max-width: 550px) {
    text-align: center;
    margin-top: 20px;
  }
}

.cardParagraph {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  @media (max-width: 550px) {
    text-align: center;
  }
}

.productWrapper {
  padding-right: 68px;

  @media (max-width: 1200px) {
    width: 100%;
    padding-right: 10px;
  }
}

.secondCards {
  display: flex;
  flex-wrap: wrap;
}

.secondCard {
  flex: 0 0 32%;
  margin-right: 1%;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 15px;
  transition: all 0.3s ease;

  & > img {
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: transparent;
    transition: all 0.3s ease;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(40px);
    border-radius: 5px;

    &:before {
      background: #e74137;
    }

    .secondTitle {
      color: #e74137;
    }
  }

  @media (max-width: 767px) {
    flex: 0 0 50%;
  }

  @media (max-width: 500px) {
    flex: 0 0 100%;
  }
}

.secondTitle {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 35px;

  &:hover {
    color: #e74137;
  }
}

.secondData {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #343753;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.secondText {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.itemImage {
  height: 130px;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: #cddbec;
    border-radius: 50%;
  }

  @media (max-width: 900px) {
    height: auto;
    width: 160px;
  }
}

.title {
  font-weight: 700;
}

.dropdownMenus {
  display: none;

  &.active {
    display: block;
  }
}

.flex {
  display: flex;
}

.searchMargin {
  margin-top: 30px;
}

.categoryTitle {
  font-family: 'Raleway';
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  color: #e74137;
  font-weight: 700;
  margin-top: 50px;
}

.button {
  background: #e74137;
  border-radius: 30px;
  font-family: 'Raleway';
  margin: 0 15px;
  height: 42px;
  border: none;
  color: #fff;
  padding: 0 32px;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & > img {
    margin: auto;
    min-width: 80px;
  }
}

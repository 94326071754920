.block {
  width: 100%;
  background: #fff;
  border-radius: 30px;
  margin-top: 37px;
  margin-bottom: 80px;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
}

.link {
  padding: 11px 62px;
  font-size: 14px;
  line-height: 16px;
  background: #e74137;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }

  & a {
    color: #ffffff;
  }
}

.apply {
  font-size: 13px;
  line-height: 15px;
  color: #a9a9a9;
  margin-top: 30px !important;
  display: flex;
  align-items: flex-start;
  position: relative;
  cursor: pointer;

  & span:first-child {
    width: 18px;
    height: 18px;
    z-index: 2;
  }

  & span > span > a {
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }

  & > span:nth-child(2) {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border: 1px solid #e74137;
    border-radius: 5px;
    z-index: 1;

    &.active {
      background: #e74137;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url('../../../media/main/order/arrow.svg') no-repeat;
        width: 14px;
        height: 11px;
      }
    }
  }

  & input[type='checkbox'] {
    width: 18px;
    height: 18px;
    opacity: 0;
    visibility: hidden;
  }
}

.margin {
  margin-left: 20px;
}

.svgActive {
  fill: #d83a2e;
  stroke: #d83a2e;
  height: 32px;
  padding-top: 2px;
}

.flexMap {
  position: relative;
  flex: 0 0 60%;
  margin-bottom: 25px;

  & > img {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.pin {
  transform: scale(1);

  &.active {
    transform: scale(1.15);
  }
}

.svgZoom {
  zoom: 0.5;
}

.flex {
  display: flex;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.flexItem {
  display: flex;
  flex-wrap: wrap;

  // @media (min-width: 993px) {
  //   margin-top: -70px;
  // }

  & > * {
    flex: 0 0 50%;
    padding-left: 20px;
  }

  @media (max-width: 992px) {
    justify-content: space-between;

    & > * {
      flex: 0 0 30%;
    }
  }

  @media (max-width: 620px) {
    & > * {
      flex: 0 0 50%;
      padding-left: 0;
    }
  }

  @media (max-width: 400px) {
    & > * {
      flex: 0 0 100%;
      text-align: center;
      padding: 5px 0;
    }
  }
}

.title {
  color: #d83a2e;
}

.wrapper {
  margin-top: 70px;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  padding: 40px;
  padding-bottom: 61px;
  margin-bottom: 100px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  background-color: #cddbec;

  @media (max-width: 420px) {
    padding: 20px;
    padding-bottom: 41px;
  }
}

.img {
  flex: 0 0 45%;
  position: relative;

  & > * {
    position: absolute;
    width: 538.5px;
    top: 0;
    left: 0;
    transform: translate(-34%, -50%);
  }

  @media (max-width: 1050px) {
    display: none;
  }
}

.containerWrapper {
  padding: 0 15px;
}

.title {
  font-size: 32px;
  line-height: 43px;
  text-transform: uppercase;
  font-weight: 700;
  color: #e74137;

  @media (min-width: 1050px) {
    white-space: nowrap;
  }

  @media (max-width: 420px) {
    font-size: 26px;
  }
}

.paragraph {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.button {
  padding: 18px 33px 21px;
  background: #e74137;
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  white-space: nowrap;

  &:hover {
    color: #fff;
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }

  @media (max-width: 420px) {
    font-size: 14px;
    padding: 18px 22px 21px;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  & > * {
    flex: 0 0 50%;
    list-style-type: disc;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;

    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }
}

.flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 45px;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
}

.flexItem {
  display: flex;
  align-items: center;
  padding: 14px 18px;
  background: #fff;
  border-radius: 20px;
  cursor: pointer;

  & img {
    transition: all 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.wrapper {
  margin-left: 27px;
}

.title {
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 7px;

  color: #000000;
}

.paragraph {
  font-size: 11px;
  line-height: 13px;

  color: #a9a9a9;
}

.mainTitle {
  font-family: 'Roboto';
  font-size: 38px;
  line-height: 45px;
  color: #000000;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin-top: 50px;
  }
}

.button {
  display: block;
  margin-bottom: 120px !important;
  text-align: center;

  & a {
    padding: 13px 30px;
    background: #eaa023;
    border-radius: 30px;
    color: #fff;
    transition: all 0.3s ease;

    &:hover {
      filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
    }
  }
}

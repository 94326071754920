.button {
  background: transparent;
  border-radius: 30px;
  width: 240px;
  padding: 11px;
  color: #000;
  border: 1px solid #eaa023;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: relative;

  &:hover {
    background: #eaa023;
    color: #fff;
  }

  &.active {
    background: #eaa023;
    color: #fff;
  }

  & > span {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  &.small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    width: auto;
    padding: 11px 30px;
  }
}

.hidden {
  display: none;
}

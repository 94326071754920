.testing-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.testing-page-images {
  width: 200px;
  height: 283px;
}

.testing-wrapper {
  display: flex;
  justify-content: space-between;
}

.testing-wrapper > a {
  flex: 0 0 25%;
  width: 25%;
  height: auto;
  object-fit: cover;
  padding: 0 15px;
}

.testing-wrapper > a > img {
  height: 200px;
}

@media (max-width: 1200px) {
  .testing-wrapper {
    flex-wrap: wrap;
  }

  .testing-wrapper > a {
    flex: 0 0 33%;
    width: 33%;
    margin-bottom: 20px;
  }
}

@media (max-width: 900px) {
  .testing-wrapper > a {
    flex: 0 0 50%;
    width: 50%;
    margin-bottom: 20px;
  }

  .testing-wrapper > a > img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 500px) {
  .testing-wrapper > a {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

a[data-fancybox] img {
  cursor: zoom-in;
}

.fancybox__backdrop::after {
  content: '';
  position: absolute;
  width: 10%;
  height: 10%;
  filter: blur(2px);
  left: 50%;
  top: 50%;
  transform: scale(11);
  opacity: 0.3;
  background-image: var(--bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.fancybox__container {
  --fancybox-bg: #000;

  --fancybox-thumbs-width: 48px;
  --fancybox-thumbs-ratio: 1;

  --carousel-button-bg: rgb(91 78 76 / 74%);

  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;

  --carousel-button-svg-strokeWidth: 2.5;
}

.fancybox__nav {
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
}

.fancybox__nav .carousel__button.is-prev {
  left: 20px;
}

.fancybox__nav .carousel__button.is-next {
  right: 20px;
}

.carousel__button.is-close {
  right: auto;
  top: 20px;
  left: 20px;
}

.fancybox__slide {
  padding: 8px 88px;
}

/* Thumbnails */
.fancybox__thumbs .carousel__slide {
  padding: 8px 8px 16px 8px;
}

.is-nav-selected::after {
  display: none;
}

.fancybox__thumb {
  border-radius: 6px;
  opacity: 0.4;
}

.fancybox__thumb:hover,
.is-nav-selected .fancybox__thumb {
  border-radius: 6px;
  opacity: 1;
}

.is-nav-selected .fancybox__thumb::after {
  display: none;
}

@media (max-width: 767px) {
  .quality-page-text {
    margin-top: 20px;
  }
}

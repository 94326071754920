.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1290px;
  background: #fff;
  margin: 0 15px;
  padding: 30px 15px;
  border-radius: 10px;

  @media (max-width: 1350px) {
    width: calc(100vw - 30px);
  }
}

.wrapper {
  width: 630px;
  margin: 0 auto !important;
  padding: 0 15px;
  overflow-x: auto;
  max-height: 90vh;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.names {
  display: flex;
  justify-content: start;
  padding-bottom: 35px;
}

.name {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  text-transform: uppercase;
  color: #343753;
  padding-right: 15px;
  transition: all 0.3s ease;

  &:hover {
    color: #e74137;
  }

  &.active {
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #e74137;
  }
}

.background {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 666;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.mobileMenu {
  @media (max-width: 1150px) {
    display: none;
  }

  & ul {
    padding: 0 10px;
  }

  overflow-y: auto;
  background: #fff;
  width: 0;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  border: 1px solid #e2e9f2;
  border-radius: 5px;
  padding-bottom: 25px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: translateX(-100%);

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 9px;
  }

  &::-webkit-scrollbar {
    height: 12px;
    width: 9px; /* ширина для вертикального скролла */
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    width: 50vw;
  }
}

.mobileWrapper {
  opacity: 0;
  visibility: hidden;
  padding-top: 10px;
  transition: all 0.5s ease;
  background: #fff;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  & li:not(.mobileLink) {
    margin-bottom: 15px;
  }
}

.mobileLink {
  font-size: 20px;
  font-family: 'Raleway';
  margin-bottom: 10px;
  position: relative;
}

.defaultLink > a {
  color: inherit;
  transition: all 0.3s ease;

  &:hover {
    color: #e74137;
  }
}

.dropdown {
  z-index: 10000;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dropdownItem {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 17px;
  background: #e2e9f2;
  width: calc(100%);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px 20px;

  & > a {
    color: inherit;
    transition: all 0.3s ease;

    &:hover {
      color: #e74137;
    }
  }
}

.flexMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  @media (max-width: 1500px) {
    flex-direction: column;
  }

  & > *:first-child {
    margin-right: 15px;

    @media (max-width: 1150px) {
      align-items: center;
    }
  }

  & * {
    font-size: 18px;
    word-break: break-all;
  }
}

.flex.changed {
  align-items: flex-start;
  margin-top: 15px;

  & > div:first-child {
    margin: 0 15px 0 0;
  }
}

.flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  & > * {
    width: auto;
  }
}

.phone {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;

  & > a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hamburgerLines {
  display: block;
  height: 26px;
  width: 32px;
  border: none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: 35px;
  right: 15px;
  z-index: 888;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width: 767px) {
    top: 15px;
  }

  @media (max-width: 400px) {
    height: 22px;
    width: 28px;
  }
}

.hamburgerLines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #000;
}

.hamburgerLines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerLines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburgerLines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerLines.active .line1 {
  transform: rotate(45deg);
  margin-top: -1px;
}

.hamburgerLines.active .line2 {
  transform: scaleY(0);
}

.hamburgerLines.active .line3 {
  transform: rotate(-45deg);
}

.defaultInput {
  border: 1px solid #343753;
  border-radius: 5px;
  width: 100%;
  padding: 18px 23px;
  font-size: 20px;
  line-height: 23px;
  color: #343753;
  margin-bottom: 14px;
  outline: none;
}

.button {
  background: #e74137;
  color: #fff;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  padding: 19px 53px;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 17px rgba(231, 65, 55, 0.51));
  }
}

.buttonWrapper {
  text-align: center;
  margin-bottom: 50px;
}

.slider-swiper .swiper-button-next.popular::after,
.slider-swiper .swiper-button-prev.popular::after {
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-swiper .swiper-button-next.popular,
.slider-swiper .swiper-button-prev.popular {
  top: 98.5%;
  width: 41px;
  height: 41px;
  background: #d9d9d9;
  border-radius: 50%;
}

.slider-swiper .swiper-button-next.popular {
  right: 38%;
}

.slider-swiper .swiper-button-prev.popular {
  left: 38%;
}

@media (max-width: 1050px) {
  .slider-swiper .swiper-button-next.popular {
    right: 33%;
  }

  .slider-swiper .swiper-button-prev.popular {
    left: 33%;
  }
}

@media (max-width: 767px) {
  .slider-swiper .swiper-button-next.popular {
    position: relative;
    right: 0;
    top: 0;
    margin-left: 15px;
  }

  .slider-swiper .swiper-button-prev.popular {
    position: relative;
    left: 0;
    top: 0;
  }

  .slider-swiper .swiper-arrows {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}

.slider-swiper .swiper-pagination-bullet {
  background: #bebebe;
  opacity: 0.7;
}

.slider-swiper .swiper-pagination-bullet-active {
  background: #8f8e8c;
  opacity: 1;
}

.slider-swiper .swiper-slide {
  background: transparent;
}

.slider-swiper .swiper {
  width: 100%;
  padding: 0 !important;
  min-height: 420px;
  padding-bottom: 60px !important;
}

.slider-swiper .swiper-pagination {
  bottom: 0;
}

.swiper-slide img {
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

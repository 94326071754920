.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 80px;
}

.text {
  margin-top: 40px;
  font-family: 'Raleway';
  font-feature-settings: 'pnum' on, 'lnum' on;

  & > h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  & a {
    color: #d83a2e;
  }

  & li {
    list-style-type: disc;
  }
}

.wrapper {
  position: relative;
  margin-bottom: 45px;
}

.title {
  font-size: 38px;
  line-height: 45px;
  color: #000000;
  margin-bottom: 31px !important;
}

.card {
  border-radius: 20px 20px 40px 20px;
  background: #fff;
  width: 100%;
  padding: 23px;
}

.image {
  margin: 0 auto 0;
}

.price {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  font-weight: 700;
  text-align: left;
  margin-top: 24px !important;
}

.name {
  font-size: 20px;
  line-height: 23px;
  font-weight: 400;
  text-align: left;
  color: #000000;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;

  & a {
    color: #000;
    font-size: 13px;
    line-height: 15px;
    padding: 9px 25px;
    border: 1px solid #bebebe;
    border-radius: 30px;
    transition: all 0.3s ease;

    &:hover {
      background: #eaa023;
      color: #fff;
      border: 1px solid transparent;
    }
  }
}

.img {
  height: 24px;
  padding: 11px;
  cursor: pointer;
  box-sizing: content-box;
  border: 1px solid #bebebe;
  border-radius: 50%;
  transition: all 0.3s ease;

  & svg {
    padding: 0;
    margin-top: -3px;
    stroke: #423f3f;
    transition: all 0.3s ease;
  }

  &:hover svg {
    stroke: #fff;
  }

  &:hover {
    background: #eaa023;
    border: 1px solid transparent;
  }
}

.logoImage {
  height: 180px;
}

.link {
  text-align: center;
  margin-bottom: 68px !important;

  & > * {
    background: #eaa023;
    border-radius: 30px;
    padding: 12px 60px;
    color: #fff;
    transition: all 0.3s ease;

    &:hover {
      filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
    }

    &:hover {
      color: #fff;
    }
  }
}

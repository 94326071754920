.custom-carousel-container .carousel-caption {
  text-align: left !important;
  color: #343753 !important;
  width: 100%;
  padding: 119px 15px 0;
  top: 0;
  left: 0;
}

.custom-carousel-container .carousel-caption .carousel-caption-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.carousel-caption-wrapper > div > h5 {
  margin-top: 15px;
}

@media (max-width: 1600px) {
  .custom-carousel-container .carousel-caption {
    padding-top: 40px;
  }
}

@media (max-width: 1300px) {
  .custom-carousel-container .carousel-caption {
    right: 0;
    left: 0;
  }
}

.custom-carousel-container .carousel-indicators {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto -138px;
}

.custom-carousel-container .carousel-indicators button {
  width: 100%;
  height: 134px;
  background: transparent;
  border-radius: 5px;
}

.custom-carousel-container .carousel-indicators li {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: #a1a1a1;
}
.custom-carousel-container .carousel-indicators .active {
  width: 100%;
  background-color: transparent;
  border-bottom: 3px solid #e74137;
  border-radius: 0;
}

@media (max-width: 1200px) {
  .custom-carousel-container .carousel-indicators button {
    border: 1px solid #a1a1a1;
    border-radius: 5px;
  }

  .custom-carousel-container .carousel-indicators .active {
    border: 1px solid #e74137;
    border-radius: 5px;
    background: #e74137;
  }
}

.custom-carousel-container .photoButton {
  background: #e74137;
  border-radius: 50px !important;
  width: 238px;
  height: 59px;
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  margin-top: 26px;
}

.custom-carousel-container .photoButton:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
}

.custom-carousel-container .frontPic-mobile {
  display: none;
}

.custom-carousel-container .frontPic,
.custom-carousel-container .frontPic-mobile {
  width: 100%;
  
}

.custom-carousel-container .carousel-control-prev,
.custom-carousel-container .carousel-control-next {
  display: none;
}

.check {
  position: relative;
}

.custom-carousel-container .control-block {
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
  color: #343753;
  height: 132px;
  margin-top: 0;
}

.control-block-row {
  height: 100%;
  align-items: center;
  background: rgba(244, 249, 255, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 5px;
}

.custom-carousel-container .control-block-info {
  height: 87.34px;
  padding: 1em 1em 0 1.5em;
  font-family: 'Roboto';
  font-weight: 400;
  color: #343753;
  text-align: center;
}

.control-block-info + .control-block-info {
  border-left: 2px solid #cecece;
}

.custom-carousel-container p {
  font-family: 'Montserrat';
  font-weight: 700;
  line-height: 64.03px;
  font-size: 48px;
  text-align: left !important;
}

.custom-carousel-container h5 {
  font-family: 'Raleway';
  font-size: 24px;
  line-height: 28px;
}

.control-block-wrapper {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
}

.carousel-button {
  background: #e74137;
  border-radius: 50px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  border: none;
  display: inline-block;
  padding: 18px 76px;
  margin-top: 40px;
  transition: all 0.3s ease;
}

.carousel-button:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  color: #ffffff;
}

@media (max-width: 1150px) {
  .custom-carousel-container .carousel-item {
    height: 450px;
  }

  .custom-carousel-container .carousel-item img {
    height: 100%;
  }
}

@media screen and (max-width: 1484px) {
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 48.03px;
    font-size: 38px;
  }
  .custom-carousel-container h5 {
    width: 70%;
  }
}

@media screen and (max-width: 1248px) {
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 42.03px;
    font-size: 34px;
  }
}

@media screen and (max-width: 1200px) {
  .custom-carousel-container .control-block {
    display: none;
  }
  .custom-carousel-container .carousel-indicators {
    margin-bottom: 20px;
    border-radius: 50px;
    justify-content: space-between;
  }
  .custom-carousel-container .carousel-indicators button,
  .custom-carousel-container .carousel-indicators button.active {
    height: 5px;
  }
}

@media screen and (max-width: 1064px) {
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 38.03px;
    font-size: 28px;
  }
  .custom-carousel-container h5 {
    font-family: 'Raleway';
    font-size: 16px;
    line-height: 28px;
  }
}

@media screen and (max-width: 976px) {
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 34.03px;
    font-size: 24px;
  }
  .custom-carousel-container h5 {
    font-family: 'Raleway';
    font-size: 16px;
    line-height: 28px;
  }
  .custom-carousel-container .frontPic,
  .custom-carousel-container .frontPic-mobile {
    height: 358.38px;
    width: 976px;
  }
}

@media (max-width: 657px) {
  .custom-carousel-container .carousel-caption div {
    text-align: center;
    width: 100%;
  }

  .custom-carousel-container .carousel-caption div > * {
    text-align: center !important;
  }
}

@media screen and (max-width: 656px) {
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 28.03px;
    font-size: 24px;
  }
  .custom-carousel-container h5 {
    font-family: 'Raleway';
    font-size: 16px;
    line-height: 20px;
  }
  .custom-carousel-container .frontPic {
    display: none;
  }
  .custom-carousel-container .frontPic-mobile {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
  }
  .custom-carousel-container .photoButton {
    width: 218px;
    height: 49px;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .custom-carousel-container p,
  .custom-carousel-container h5 {
    width: 100%;
  }
}

@media screen and (max-width: 454px) {
  .custom-carousel-container .carousel-caption {
    width: 100%;
  }
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 28.03px;
    font-size: 24px;
    text-align: center !important;
    margin: 0 auto;
  }
  .custom-carousel-container h5 {
    font-family: 'Raleway';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 0 auto;
  }
  .custom-carousel-container div {
    width: 100%;
    text-align: center;
  }
  .custom-carousel-container .photoButton {
    width: 218px;
    height: 49px;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .custom-carousel-container .carousel-indicators {
    margin-bottom: 20px;
    border-radius: 50px;
    justify-content: center;
  }
  .custom-carousel-container .carousel-indicators button,
  .custom-carousel-container .carousel-indicators button.active {
    height: 20px;
    width: 20px;
    border-radius: 100%;
  }
}

@media screen and (max-width: 454px) {
  .custom-carousel-container .carousel-caption {
    width: 100%;
  }
  .custom-carousel-container p {
    font-family: 'Montserrat';
    line-height: 26.03px;
    font-size: 22px;
    text-align: center !important;
    margin: 0 auto;
  }
  .custom-carousel-container h5 {
    font-family: 'Raleway';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin: 0 auto;
  }
  .custom-carousel-container div {
    width: 100%;
    text-align: center;
  }
  .custom-carousel-container .photoButton {
    width: 218px;
    height: 49px;
    font-size: 16px;
    line-height: 21px;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .custom-carousel-container .carousel-indicators {
    margin-bottom: 20px;
    border-radius: 50px;
    justify-content: center;
  }
  .custom-carousel-container .carousel-indicators button,
  .custom-carousel-container .carousel-indicators button.active {
    height: 20px;
    width: 20px;
    border-radius: 100%;
  }
}

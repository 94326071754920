.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 0;
  }
}

.flexItem {
  flex: 0 0 calc(50% - 15.5px);
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding: 20px 23px;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 10px !important;
}

.inputRadio {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  margin-bottom: 9px;

  & label {
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #000000;
    user-select: none;
  }

  &:hover label {
    text-decoration: underline;
  }
}

.input {
  opacity: 0;
  visibility: hidden;
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  flex: 0 0 15px;
  cursor: pointer;

  & + span {
    position: absolute;
    cursor: pointer;
    width: 15px;
    height: 15px;
  }

  & + span:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border: 1px solid #d9d9d9;
    top: 0;
    left: 0;
    border-radius: 50%;
    padding: 3px;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  & + span:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 9px;
    height: 9px;
    background: transparent;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &:checked + span:after {
    content: '';
    border: 1px solid #eaa023;
  }

  &:checked + span:before {
    content: '';
    background: #eaa023;
  }
}

.paragraph {
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px !important;
  color: #423f3f;
  padding-left: 23px;
}

.extraTypes {
  margin-left: 26px;
  margin-top: 9px;
}

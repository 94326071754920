.labels {
  text-align: center;
}

.labels p {
  font-family: 'Raleway';
  color: #e74137;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
}

.certList {
  text-align: center !important;
  justify-content: space-around;
  margin-top: 60px !important;
  padding: 0 0 5em 0;
}

/*------------------- certificate carousel -----------------*/

.certList-wrapper {
  max-width: 1300px;
  padding: 0 15px;
  margin: 70px auto 0;
}

.certCarousel,
.revCarousel {
  /* padding: 0em 0 4em 0; */
  margin-top: 80px;
}
.certCarousel .carousel-indicators {
  justify-content: center !important;
  background: none;
  backdrop-filter: none;
  margin-bottom: -55px !important;
  width: 100%;
  margin-left: 0 !important;
}

.certCarousel .carousel-indicators button {
  width: 17px !important;
  border-radius: 50%;
  background: #343753 !important;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.certCarousel .carousel-item {
  padding: 0em 0;
}

.certCarousel .carousel-item .row {
  padding: 0em 0em 6em 0em;
  width: 83%;
  margin: 0 auto;
}

.certCarousel .card-body {
  text-align: center;
}

.certCarousel .carousel-control-prev-icon {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  margin-right: 100px;
}

.certCarousel .carousel-control-next-icon {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  margin-left: 100px;
}

.certCarousel .carousel-control-prev-icon,
.certCarousel .carousel-control-next-icon {
  padding: 0em 1em !important;
  border-radius: 0;
}
/* ---------------------------------------------------- */

/*------------------- reviews carousel -----------------*/
.revCarousel .carousel-indicators {
  justify-content: center !important;
  background: none;
  backdrop-filter: none;
  margin-bottom: -55px !important;
}

.revCarousel .carousel-indicators button {
  width: 23px !important;
  border-radius: 200px;
  background: #343753 !important;
}

.revCarousel .carousel-item {
  padding: 0em 0;
}

.revCarousel .carousel-item .row {
  padding: 0em 0em 6em 0em;
  width: 83%;
  margin: 0 auto;
}

.revCarousel .card-body {
  text-align: center;
}

.revCarousel .carousel-control-prev-icon {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
  margin-right: 140px;
}

.revCarousel .carousel-control-next-icon {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  margin-left: 140px;
}

.revCarousel .carousel-control-prev-icon,
.revCarousel .carousel-control-next-icon {
  padding: 0em 1em !important;
  border-radius: 0;
}
/* ---------------------------------------------------- */

.listCol {
  padding: 0 4em !important;
}

.revCol.modify {
  margin-top: 100px;
}

@media (max-width: 1100px) {
  .listCol {
    margin: 0 auto;
    width: 50%;
  }

  .certList {
    padding-bottom: 25px;
  }

  .certCarousel,
  .revCarousel {
    margin-top: 30px;
  }

  .revCol.modify {
    margin-top: 130px;
  }
}

@media screen and (max-width: 964px) {
  .certList {
    display: block;
  }
  .listCol {
    width: 70%;
    padding: 0;
    margin: 0 auto;
  }
  .revCol {
    margin-top: 150px;
  }
  .certCarousel.carousel.slide.carousel-dark a.carousel-control-prev,
  .certCarousel.carousel.slide.carousel-dark a.carousel-control-next {
    display: none;
  }
  .revCarousel.carousel.slide.carousel-dark a.carousel-control-prev,
  .revCarousel.carousel.slide.carousel-dark a.carousel-control-next {
    display: none;
  }
  .labels p {
    font-family: 'Raleway';
    color: #e74137;
    font-size: 28px;
    line-height: 38px;
  }

  .certCarousel,
  .revCarousel {
    /* padding: 0em 0 4em 0; */
    margin-top: 40px;
  }
}

@media (max-width: 700px) {
  .listCol {
    margin: 0 auto 130px;
  }
}

@media (max-width: 550px) {
  .listCol {
    width: 100%;
    padding: 0 15px !important;
  }
}

.production-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.production-page-info {
  margin-top: 5%;
}

.production-page-info * {
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.production-page-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.3em;
}

.production-page-text span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26.18px;
}

.production-page-row {
  margin-top: 30px;
}

@media screen and (max-width: 1352px) {
  .production-page-row {
    display: block;
  }
}

.banner {
  position: relative;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.flex {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 54px 46px;
  background: url('../../../media/main/background.png') no-repeat;
  border-radius: 30px;
  margin-bottom: 94px;
  background-size: cover;
  background-position: center;

  @media (max-width: 767px) {
    padding: 36px;
    background-position: left;
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.paragraph {
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-top: 20px !important;
  margin-bottom: 40px !important;
}

.link {
  & > a {
    padding: 10px 40px 11px;
    background: #eaa023;
    border-radius: 30px;
    color: #fff;
    transition: all 0.3s ease;

    &:hover {
      filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
    }
  }
}

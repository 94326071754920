.drops {
  height: 0;
  position: relative;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  background: #f7fbff;
  border-radius: 5px;
  padding: 0 10px;

  &.active {
    opacity: 1;
    visibility: visible;
    height: auto;
    padding: 5px 10px;
  }
}

.dropdown {
  position: relative;
}

.title {
  padding: 5px 10px;
  background: #cddbec;
  border-radius: 5px;
  margin: 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.active {
    & img {
      transform: rotate(180deg);
    }
  }
}

.itemDropdown {
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    color: #e74137;
  }

  &.active {
    color: #e74137;
  }
}

.container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;

  @media (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  gap: 1%;

  @media (max-width: 550px) {
    gap: 0;
  }
}

.item {
  flex: 0 0 24%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  overflow: hidden;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  & > div {
    width: 100%;
  }

  &:hover {
    border: 1px solid #d83a2e;
  }

  @media (max-width: 1150px) {
    flex: 0 0 32%;
  }

  @media (max-width: 860px) {
    flex: 0 0 49%;
  }

  @media (max-width: 550px) {
    flex: 0 0 100%;
  }
}

.img {
  object-fit: cover;
  width: 100%;
}

.link {
  color: #d83a2e;
  font-family: 'Raleway';
  position: absolute;
  bottom: 0;
  padding: 10px 0;
  display: inline-flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 30px;

  &:hover {
    text-decoration: underline;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    background-size: cover;
    width: 10px;
    height: 10px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSIjZDgzYTJlIj48cGF0aCBkPSJNNy4zMyAyNGwtMi44My0yLjgyOSA5LjMzOS05LjE3NS05LjMzOS05LjE2NyAyLjgzLTIuODI5IDEyLjE3IDExLjk5NnoiLz48L3N2Zz4=');
  }
}

.title {
  font-family: 'Raleway';
  display: block;
  font-weight: bold;
  text-align: left;
  width: 100%;
  padding: 0 15px 70px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  margin-top: 14px;
  margin-bottom: 0.6em;
  font-size: 18px;
  line-height: 24px;
  color: initial;
}

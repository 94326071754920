.history-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 50px;
}

.history-page-images {
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.graphic {
  display: inline-block;
  height: auto;
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .graphic {
    display: none;
  }
}

.history-page-info {
  margin-top: 5%;
}

.history-page-info * {
  
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.history-page-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 26.18px;
  /* margin-top: 10%; */
  padding: 2em 0 0 0;
}

.history-page-text span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26.18px;
}

.history-page-row {
  margin-top: 30px;
}

.hpr-image-section {
  text-align: center;
}

@media screen and (max-width: 1255px) {
  .hpr-2 {
    display: block;
  }
}

@media screen and (max-width: 1191px) {
  .hpr-4 {
    display: block;
  }
  .hpr-image-section {
    margin-top: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .hpr-1,
  .hpr-2,
  .hpr-3,
  .hpr-5 {
    display: block;
  }
}

@media screen and (max-width: 720px) {
  .hpr-1-row {
    display: block;
  }
}

.press-card-page-conteiner {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto 110px;
}

.press-card-page-row {
  margin-top: 3%;
}

.press-card-page-col {
  margin-top: 20px;
}

.press-card-name {
  text-transform: uppercase !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #343753;
}

.press-card-info-text {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.press-card-info-text img {
  max-width: 100%;
}

.all-news {
  background: #e74137;
  border-color: #e74137;
  padding: 1em 4em;
  border-radius: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  border-style: none;
}

.all-news:hover {
  background: #ff493f;
  border-color: #ff493f;
}

.all-news:active {
  background: #aa2d26;
  border-color: #aa2d26;
}

.card-photo-section {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .press-card-page-row {
    display: block;
  }
  .press-card-page-col {
    width: 100%;
  }
}

.press-card-local-link {
  margin-top: 21px;
  display: inline-block;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 19px 55px;
  background: #e74137;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.press-card-local-link:hover {
  filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
}
.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
  margin-bottom: 15px;
}
.fluid-width-video-wrapper iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .press-card-local-link {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}

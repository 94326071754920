.clients-page-conteiner {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  margin-bottom: 70px;
}

.clients-page-row {
  text-align: center;
}

.clients-page-col {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-image {
}

.flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  &:not(.changed) {
    align-items: center;
    margin-top: 0 !important;
  }

  & > * {
    width: auto;
  }
}

.flexHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  &:not(.changed) {
    align-items: center;
    margin-top: 0 !important;
  }

  & > * {
    width: auto;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    gap: 16px;
  }
  
}

.phonesLaptop {
  @media (max-width: 1150px) {
    display: none !important;
  }
}

.flexWrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;

  @media (min-width: 1150px) {
    margin-bottom: 20px !important;
  }

  & > * {
    width: auto;
  }

  @media (max-width: 1150px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }

  & > div:first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1150px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.modalWindow {
  width: 1290px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  transform: translate(-50%, -50%);
}

.modalWrapper {
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  width: 200vw;
  height: 200vh;
  position: fixed;
  top: -50vh;
  left: -50vw;
  backdrop-filter: blur(0);

  &.active {
    backdrop-filter: blur(1px);
  }
}

.counter {
  position: absolute;
  top: -3px;
  font-size: 12px;
  background: #e74137;
  border-radius: 50%;
  padding: 0 5px;
  color: #fff;
}

.itemImageWrapper {
  position: relative;
  height: 18px;
  padding-right: 20px;
  margin-top: -2px !important;

  & > img {
    height: 18px;
  }

  @media (max-width: 1150px) {
    margin-top: -3px;
  }
}

.width {
  @media (max-width: 1150px) {
    margin: 0 auto !important;
  }

  @media (max-width: 879px) {
    display: block;
    margin: 0 auto !important;
  }

  @media (max-width: 600px) {
    margin: 0 0 20px !important;
  }
}

.width > a {
  width: auto !important;
}

.text {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 8px !important;
}

.phone {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;

  & > a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.phonesHeader {
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 25px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin: 0;

  & > a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.margins {
  margin-right: 15px !important;
}

.image {
  width: 51px;
  height: 51px;

  &:first-child {
    margin-right: 8px;
  }

  @media (min-width: 601px) {
    &:nth-child(2) {
      margin-right: 15px;
    }
  }
}

.flex.modify {
  margin: 0 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1150px) {
    margin: 0;
  }
}

.flex.changed {
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 15px;

    @media (max-width: 1150px) {
      margin: 15px auto 0;
    }

    @media (max-width: 600px) {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  & > div:first-child {
    margin: 0 15px 0 0;
  }
}

.flex.iconSpace {
  & > div:first-child {
    margin: 0 15px 0 0;
  }
}

.flex.mobile {
  @media (max-width: 500px) {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    margin-bottom: 20px;

    & > * {
      padding-left: 15px;
      width: 100%;
    }

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.mainText {
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 21px;
}

.maxWidth {
  max-width: 370px;

  @media (max-width: 1150px) {
    max-width: inherit;
    width: 100%;
    text-align: center;
    display: block;
    margin: 0 auto 0 auto !important;
  }

  @media (max-width: 879px) {
    max-width: 325px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.logo {
  max-width: 290px;
  @media (max-width: 879px) {
    display: block;
    margin: 0 auto;
  }

  @media (max-width: 400px) {
    width: 230px;
  }
}

.description {
  align-items: center;
  @media (max-width: 879px) {
    max-width: 371px;
    margin: 0 auto !important;
  }
}

.itemMenu > *:not(:last-child):not(:first-child) {
  padding-right: 50px;
  border-right: 1px solid #e2e9f2;
  display: flex;
  align-items: center;
}

.itemMenu > li {
  text-align: center;
}

.personalLi {
  margin-left: 45px;
}

.dropdown {
  position: absolute;
  padding-top: 25px;
  top: 100%;
  left: 50%;
  z-index: 10000;
  transform: translateX(-50%);

  @media (max-width: 1150px) {
    position: relative;
    width: 95%;
    padding-top: 10px;
    padding-bottom: 15px;
  }
}

.dropdownItem {
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 17px;
  background: #e2e9f2;
  border-radius: 5px;
  margin-bottom: 2px;
  padding: 10px 20px;

  & > a {
    color: inherit;

    &:hover {
      color: #e74137;
    }
  }
}

.menuItem {
  position: relative;

  @media (min-width: 1150px) {
    text-align: center;
  }

  & > * {
    margin-bottom: 0;
  }
}

.navbar {
  padding: 12px 0;
  margin-bottom: 0;
}

.navbar > li {
  font-family: 'Roboto';
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 12px 0;
  font-weight: 700;

  & > a {
    color: #000000;
  }
}

.firstBackground {
  width: 100vw;
  height: 100%;
  position: absolute;
  background: #ebeef2;
}

.secondBackground {
  width: 100vw;
  height: 100%;
  position: absolute;
  background: #cddbec;
}

.backgroundWrapper {
  position: relative;

  @media (max-width: 1150px) {
    display: none;
  }
}

.hamburgerLines {
  display: block;
  height: 26px;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  width: 32px;
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.active {
    left: initial;
    right: 15px;
  }

  &.modify {
    top: 19px;
    left: 15px;
  }

  &:not(.modify) {
    @media (min-width: 1150px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    top: 15px;
  }

  @media (max-width: 400px) {
    height: 22px;
    width: 28px;
  }
}

.hamburgerLines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #000;
}

.hamburgerLines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerLines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburgerLines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerLines.active .line1 {
  transform: rotate(45deg);
  margin-top: -1px;
}

.hamburgerLines.active .line2 {
  transform: scaleY(0);
}

.hamburgerLines.active .line3 {
  transform: rotate(-45deg);
}

.mobileMenu {
  display: none;

  & ul {
    padding: 0 10px;
  }

  @media (max-width: 1150px) {
    opacity: 0;
    visibility: hidden;
    padding-top: 25px;
    overflow-y: auto;
    background: #fff;
    width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 888;
    border: 1px solid #e2e9f2;
    border-radius: 5px;
    transition: all 0.3s ease;
    display: block;
    padding-bottom: 25px;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 767px) {
    width: 100vw;
    left: 0;
  }
}

.mobileLink {
  font-size: 20px;
  padding-bottom: 15px;
  font-family: 'Raleway';
}

.defaultLink > a {
  color: inherit;
}

.personalCabinet {
  margin: 0 !important;
  display: flex;
  justify-content: center;

  @media (min-width: 1261px) {
    padding-top: 3px;
  }

  @media (max-width: 500px) {
    width: 100%;

    text-align: center;
  }
}

.logoText {
  font-family: 'Raleway';
  font-size: 13px;
  line-height: 19px;
  margin-top: 7px !important;

  @media (max-width: 767px) {
    display: none;
  }
}

.button {
  margin: 0;
  color: #000;
  font-family: 'Raleway';

  & > button {
    padding-bottom: 0;
  }

  & a {
    color: #000;
  }

  &:hover {
    text-decoration: underline;
  }

  &.modify {
    margin-right: 15px;
  }
}
.itemWrapper {
  display: flex;
  align-items: center;

  &:first-child {
    @media (min-width: 1150px) {
      margin-left: 20px !important;
      margin-bottom: 5px;
    }
  }

  & > img {
    height: 18px;
  }
}

.btn {
  background: #e74137;
  border-radius: 30px;
  font-family: 'Raleway';
  height: 42px;
  font-size: 13px;
  border: none;
  color: #fff;
  padding: 0 25px;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(231, 65, 55, 0.51));
  }
}

.flexFooterMainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.unusualFlex {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  align-items: center;

  @media (max-width: 1150px) {
    margin-bottom: 20px !important;
  }

  @media (max-width: 1150px) {
    margin-top: 30px !important;
    align-items: center;
  }

  @media (max-width: 600px) {
    margin-top: 10px !important;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.flexLogo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 !important;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.changeLanguage {
  font-family: 'Raleway';
  background: linear-gradient(90deg, #d9d9d9 2.05%, rgba(217, 217, 217, 0) 74.18%);
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  padding: 5px 11px;
  position: relative;

  & > span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    padding: 7px 13px;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  & > span > a {
    color: #000000;
  }

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    background-color: #fff;
    border-radius: 30px;
    height: calc(100% - 10px);
    width: calc(33% - 11px);
    z-index: 1;
    transition: all 0.3s ease;
  }

  &.left {
    &:before {
      left: 11px;
    }
  }

  &.center:before {
    left: 36%;
  }

  &.right {
    &:before {
      left: calc(100% - 11px);
      transform: translateX(-100%);
    }
  }
}

.flexFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  @media (max-width: 1150px) {
    margin: 20px 0 !important;
    flex-direction: row;
    flex-direction: column;
    gap: 16px;
  }

  & > div:not(:first-child) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1150px) {
      margin: 0 auto;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: center;

      & > * {
        margin-bottom: 15px;
      }
    }
  }

  & > div:last-child {
    // margin-top: 10px;

    @media (max-width: 500px) {
      margin-top: 0;
    }
  }

  // @media (max-width: 500px) {
  //   margin-top: 0 !important;
  // }
}

.flexFooterWrapper {
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;

    & > *:first-child {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 450px) {
    & > *:last-child {
      flex-direction: column;
    }
  }
}

.flexMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  & > *:first-child {
    margin-right: 15px;

    @media (max-width: 1150px) {
      align-items: center;
    }
  }

  & * {
    font-size: 18px;
    word-break: break-all;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.modal {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: scale(0.8);
  z-index: 10000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

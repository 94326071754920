.image {
  width: 100px;
  transform: rotate(80deg);
  position: absolute;
  top: -50px;
  left: 50px;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 0 0 50%;

    @media (max-width: 900px) {
      flex: 0 0 100%;
    }
  }
}

.flexItem {
  display: flex;
  margin: 10px 0;
  position: relative;
  min-height: 117px;
  overflow: hidden;
  background: linear-gradient(90deg, #fcfcf8 0, white 74%);

  @media (max-width: 500px) {
    background: linear-gradient(90deg, #f9f9f9 0%, rgba(255, 255, 255, 1) 118%);
  }
/*
  &:hover {
    cursor: pointer;
    & .title {
      color: #e74137;
    }
  }*/

  & > div {
    padding: 15px 15px 15px 235px;

    @media (max-width: 900px) {
      padding-top: 26px;
    }

    @media (max-width: 500px) {
      padding: 143px 15px 0;
    }
  }
}

.title {
  font-weight: 700;
  transition: all 0.3s ease;
}

.FormTitle {
  text-align: center;
  color: #e74137;
  font-family: "Raleway";
  font-size: 36px;
  font-weight: bold;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;

  input, textarea {
    border-color: #343753;
    border-radius: 5px;
    padding: 12px 18px;
    font-size: 18px;
    color: #343753;
    outline: none;

    &:focus {
      border-color: #6571a8;
      box-shadow: 0 0 2px 2px rgba(100, 112, 167, 0.5)
    }
  }
}

.FormSubmitButtonContainer {
  display: flex;
  justify-content: center;
}

.FormSubmitButton {
  background: #e74137;
  color: #fff;
  border-radius: 50px;
  border: none;
  padding: 19px 53px;
  transition: all 0.3s ease;

  &:hover {
    background: #bc2b22 !important;
    filter: drop-shadow(0px 15px 17px rgba(231, 65, 55, 0.51));
  }
}

.product-page-container {
  max-width: 1300px;
  padding: 0 15px;
  margin: 0 auto;
}

.product-page-wrapper {
  position: relative;
  margin-bottom: 80px;
}

.product-page-image {
  background-image: url('../../backCab.png');
  background-position: right;
  background-repeat: repeat-y;
  background-size: 100vh;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.product-page-row {
  margin-top: 50px;
  justify-content: center;
}

.product-page-container .cardImg {
  height: auto;
  margin: 0 auto;
}

.product-page-container .product {
  transition: all 0.3s ease;
  padding: 0 0;
  text-align: center;
}

.product-page-container .product:hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

.product-page-container .product:hover {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 5px;
  border-bottom: 1px solid #e74137;
}

.product-page-container .drop-buttons {
  background: #cddbec;
  border: none;
  color: black;
  width: 100% !important;
  text-align: left;
  box-shadow: none !important;
}

.product-page-container .product-carousel-card-body {
  padding: 1rem 1em;
}

.product-page-container .product-carousel-card-body p {
  text-align: center;
}

.product-page-container .drop-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: normal;
}

.product-page-container .nav-tabs {
  border: none;
}

.product-page-container .nav-tabs .nav-link.active {
  border: none;
  background: #cddbec;
}

.product-page-container .show > .btn-primary.dropdown-toggle {
  color: black;
  background: #cddbec !important;
}

.product-page-container .cardsBlock {
  margin-top: 20px;
}

.product-page-container .cardsTab {
  margin-top: 5%;
}

.product-page-container .cardsBlock {
  margin-left: 10%;
}

.mt-1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.product-page-container .nav-tabs .nav-link,
.product-page-container .nav-tabs .nav-link.active {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

@media screen and (max-width: 1200px) {
  .product-page-row {
    display: block;
  }
  .productsLabel {
    width: 100%;
    text-align: center !important;
    margin: 0 !important;
  }
  .products {
    width: 100%;
    margin-top: 5%;
  }
  .product-menu {
    width: 100%;
  }
}

.product:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #e74137;
}

.product:hover .product-name {
  color: #e74137;
}

.custom-link {
  color: rgb(0, 0, 0) !important;
  text-decoration: none !important;
}

.drop-menu.dropdown-menu.show {
  position: relative !important;
  transform: none !important;
  margin-top: 2px !important;
  background: #ebeef2;
}

.drop-menu.dropdown-menu.show a.dropdown-item:hover {
  color: #e74137;
  background: #ebeef2;
}

.drop-menu.dropdown-menu.show a.dropdown-item {
  white-space: normal;
}

.dropdown-toggle::after {
  display: none;
}

.product-carousel-round-2 {
  display: none;
}

.product:hover .product-carousel-round {
  display: none;
}

.product:hover .product-carousel-round-2 {
  display: inline-block;
}

.product {
  align-items: center;
}

.product-type-link {
  color: #000;
}

.product-card-label {
  font-family: 'Raleway';
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #343753;
}

.products .industry-product-card .card-body {
  text-align: left;
  z-index: 100;
  position: relative;
}

.products .industry-product-card {
  border: 1px solid lightgrey;
}

.products .custom-link {
  padding-right: 0;
}

@media screen and (max-width: 1600px) {
  .products .industry-product-card-circle {
    max-width: 100%;
  }
  .products .card-text {
    font-size: 16px;
    line-height: 18px;
  }
  .products .product-name {
    font-size: 18px;
  }
  .products .industry-product-card {
    width: 96%;
  }
}

@media screen and (max-width: 1124px) {
  .products .industry-list-row {
    display: block;
  }

  .products .industry-list-col {
    width: 70%;
    padding-right: 0;
  }

  .products .industry-product-card.card .row.row-cols-md-2 {
    margin: auto;
  }
  .products .card-body {
    margin: auto;
  }
  .products .industry-label {
    font-size: 28px;
  }
  .products .industry-product-card {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  .products .industry-list-col {
    width: 100%;
    display: block;
  }
  .products .industry-product-card.card .row.row-cols-md-2 {
    display: block;
  }
  .products .card-body {
    padding: 0 1em 2em;
  }

  .products .industry-product-card .card-body {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .products .industry-product-card-circle {
    height: 120px;
    float: left;
  }
  .products .industry-list-container {
    width: 96%;
  }
  .products .industry-list-col.col div.row {
    /* --bs-gutter-x:none   ; */
    --bs-gutter-y: none;
  }
  .products .custom-link {
    padding-left: 0;
  }
}

@media screen and (max-width: 444px) {
  .products .industry-product-card-circle {
    height: 100px;
    float: left;
  }
  .products .card-text {
    display: none;
  }
  .products .industry-product-card {
    height: 110px;
  }
  /* .card-body{
        margin-top: 20px;
    } */
  .products .product-name {
    font-size: 14px;
  }
  .products .industry-label {
    font-size: 24px;
  }
}

.product-card-body > * {
  padding-top: 0;
}

.page {
  padding: 0.5em 0.5em;
  cursor: pointer;
  border: 1px solid #eaa023;
  margin-right: 5px;
  border-radius: 5px;
  transition: all 0.3s ease;

  &.active {
    background: #eaa023;
    color: #fff;
  }
}

.changeLanguage {
  background: linear-gradient(90deg, #ffffff 2.05%, #ffffff 74.18%);
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  height: 52px;
  position: relative;

  & > span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #a9a9a9;
    padding: 5px 13px;
    flex: 0 0 50%;
    text-align: center;
    z-index: 2;
    transition: all 0.3s ease;
    cursor: pointer;

    &.active {
      color: #000000;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    background-color: #fff;
    border-radius: 30px;
    height: calc(100% - 10px);
    width: calc(50% - 8px);
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
    z-index: 1;
    transition: all 0.3s ease;
  }

  &.left {
    &:before {
      left: 11px;
    }
  }

  &.right {
    &:before {
      left: 50%;
    }
  }

  &.languageMobile {
    @media (min-width: 400px) {
      display: none;
    }

    @media (max-width: 400px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.body {
  background: #eeedea;
  font-family: 'Roboto';
}

.container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 30px 15px;

  & p {
    margin: 0;
    margin-bottom: 20px;
  }


  h1{
    margin: 0 0 30px ;
  }

  ul {
    li {

      list-style: disc;
    }
  }
}


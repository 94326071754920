.awards-container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
  margin-bottom: 70px;
}

.awards-info {
  margin-top: 20px;
}

.awards-info * {
  
  font-family: "Raleway";
  font-feature-settings: 'pnum' on, 'lnum' on;
}

.awards-info > .row {
  margin-left: 0;
}

.awards-certificates-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.awards-laureat-block {
  display: flex;
  flex-flow: row wrap;
  gap: 14px;
}

.awards-laureat-block .awards-laureat-image {
  padding-bottom: 16px;
}

.awards-column {
  flex: 1 0 0%;
  align-self: center;
}

.awards-laureat-image {
  width: 100%;
}

.awards-col-text {
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}

@media (max-width: 1024px) {
  .awards-laureat-block {
    flex-direction: column;
    gap: 0;
  }

  .awards-certificates-block {
    grid-template-columns: repeat(2, 1fr);
  }
}